import React,{useEffect, useState,useCallback} from 'react';
import { useNavigate} from 'react-router-dom';
import {Row, Button, Form, FormControl, Nav, Navbar, NavDropdown, Container, Col, Tab, InputGroup} from 'react-bootstrap';
import Select from 'react-select';
import {ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import { getSelectSucursalesApi } from '../../api/Sucursal';
import { getDatosUsers } from '../../api/Login';
import { getTokenApi } from '../../api/Token';
import { getSucursalProductobyIdApi } from '../../api/ProductoSucursal';
import FilasVentas from './FilasVentas';

import { setVentaNuevaApi } from '../../api/Ventas';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LoadingSpinner from '../views/LoadingSpinner';
const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? '#black' : 'black',
      backgroundColor: state.isSelected ? '#5243aa' : 'white',
      backgroundColor: state.isFocused ? '#e8f0fe' : 'white',
    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }
const defaultStateVentas = {
    cantidad: 0,
    producto:0,
    unidad: "",
    total: "",
};
  
function Ventas(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    const [sucursales,setSucursales] = useState(null);
    //const [valorSucursal,setValorSucursal] = useState(null);
    const [prodcutos,setProductos] = useState(null);
    const [prodcutosA,setProductosA] = useState(null);
    const [fventas,setVentas] = useState([])
    const [valoresSelect] = useState([]);
    const [pago,setPago] = useState(0);
    const [total,setTotal] = useState(0);
    let navigate = useNavigate();
    let timer = 0;
    let delay = 300;
    let prevent = false;
    const getProductos = useCallback( async(valorSucursal) => {
        //setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idSucursal": valorSucursal,
            "token" : TOKEN,
          }
        var option = [];
        var productoS = await getSucursalProductobyIdApi(formdata);
        productoS.productoSucursal.map((ps,index)=>(
            option.push({
                value:ps.id_producto_sucursal,
                label:ps.clave_producto+" "+ps.descripcion_producto
            })
        ));
        setProductos(option);
        setProductosA(productoS.productoSucursal);
        //setIsLoading(false);
    },[]);
    /* select multiple opciones */
    var selectSucursal = (e) =>{
        document.getElementById('totalGlobal1').textContent = "Total ";
        getProductos(e.value);
    }
    /*obtener sucursales*/
    useEffect(()=>{
        (async()=>{
            const TOKEN = await getTokenApi();
            const formdata = {
                "token" : TOKEN,
            }
            var option = [];
            const sucursal = await getSelectSucursalesApi(formdata);
            if(sucursal.error == null){
                sucursal.sucursales.map((suc,index)=>(
                    option.push({
                        value:suc.id_sucursal,
                        label:suc.clave_sucursal+" "+suc.nombre_sucursal
                    })
                ));
                setSucursales(option);  
            }
                         
          })();
    },[]);
  /*Verificar si no ahy un usuario logueado*/
    useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            if(user.usuario_sucursal != null){
                getProductos(user.usuario_sucursal);
            }else{
            }
            setAuth(user);
        }
        }
        init();
    }, [])
    var prodcutoAdd = (index, name, value) => {
       // setVentas(fventas.concat(defaultStateVentas));
        actualizarFilas(fventas.concat(defaultStateVentas));
        //console.log('prodcutoAdd',fventas.length);
      };
    
    var handleOnChange = (index, name, value) => {
        var errorArray = false;
       console.log("handle index",index);
       console.log("handle name",name);
       console.log("handle value ",value);
        
        if ("productos[]" == name) {
          //  console.log('llenar el arreglo',fventas.length);
          //recorrer arreglo valoresselect para no permitir 
          //duplicados
          if(Array.isArray(valoresSelect) && valoresSelect.length != 0) {
            
            map(valoresSelect,(vs,indexS)=>{
                if(vs == value){
                    errorArray = true;
                }
            })
            if(errorArray == true){
                alert('No puedes duplicar el producto');
            }else{
                valoresSelect[index] = value;
            }
          }else{
            valoresSelect[index] = value;
          }            
        }
       // console.log(valoresSelect);
        //console.log(valoresSelect.length);
        /*buscar que producto es para hacer las operaciones pertinentes*/
        if(Array.isArray(valoresSelect) && valoresSelect.length != 0) {
            map(prodcutosA,(pa) =>{
                if (pa.id_producto_sucursal == valoresSelect[index]) {
                     /*obtenemos la cantidad y ponemos el valor por pieza y el total*/
                    let pu = document.getElementById("precioR"+index);
                   
                    let im = document.getElementById("importeR"+index);
                    if (pu != null) {
                        pu.textContent = "$"+String(pa.precio_producto_sucursal);
                    }
                    
                    if (im!= null) {
                        im.textContent = String(pa.existencia_producto_sucursal);
                    }
                }
            });
            /*global total*/
            let totalGlobal = 0;
            map(valoresSelect,(vs,indexS)=>{
               // console.log('global',vs,indexS);
                map(prodcutosA,(pa) =>{
                    if (pa.id_producto_sucursal == vs) {
                       // console.log(pa);
                        /*obtenemos la cantidad y ponemos el valor por pieza y el total*/
                        let cantidadP = document.getElementsByName("cantidad[]")[indexS].value;
                        if (cantidadP != null && cantidadP != "") {
                            if ( parseInt(cantidadP) <=  parseInt(pa.existencia_producto_sucursal)) {
                                //     console.log("cantidadpyexistencia",cantidadP,pa.existencia_producto_sucursal);
                                 } else {
                                     document.getElementsByName("cantidad[]")[indexS].value = pa.existencia_producto_sucursal;
                                     window.alert('La cantidad del producto excede a la cantidad del producto en existencia');
                                 } 
                        }
                                             
                        let pt = document.getElementsByClassName("totalR[]");
                        let total = parseInt(cantidadP) * parseInt(pa.precio_producto_sucursal);
                        if (pt!=null) {
                            pt[indexS].textContent = "$"+String(total);
                        }
                        totalGlobal = totalGlobal + total;
                      //  console.log(totalGlobal);
                    }
                });
                })
                setTotal(totalGlobal);
               document.getElementById('totalGlobal1').textContent = "Total $" + String(totalGlobal);
            }
            if(errorArray != true){
                const copyRows = [...fventas];
        copyRows[index] = {
          ...copyRows[index],
          [name]: value
        };
                actualizarFilas(copyRows);
            }
            
      };
    var handleOnRemove = (index) => {
       // console.log("index",index);
       const copyRows = [...fventas];
       //console.log("a",copyRows);
       copyRows.splice(index, 1);
       //console.log("d",copyRows);
       valoresSelect.splice(index,1)
       //console.log("val",valoresSelect);
       //setVentas(copyRows);
       actualizarFilas(copyRows);
    };
    const actualizarFilas = useCallback( async(filas) => {
        setVentas(filas)
    },[fventas]);
    var confirmarCompra = async() => {
        
        /*console.log(auth);
        console.log(document.getElementsByName('selectSucursal')[0].value);
        console.log(document.getElementsByName('selectSucursal').length);
        console.log('productos',valoresSelect);
        console.log('cantidad',document.getElementsByName('cantidad[]'));
        /*creamos el post para que se mande y se agregue a la base de datos*/
        let cantidadesPost = [];
        for (let i = 0; i < document.getElementsByName('cantidad[]').length; i++) {
            cantidadesPost[i] = document.getElementsByName('cantidad[]')[i].value;
            
        }
        const nomCliente = document.getElementById('nomCliente').value;
        const TOKEN = await getTokenApi();
        const formdata = {
            "token" : TOKEN,
            "cantidades" : cantidadesPost,
            "productos" : valoresSelect,
            "idUsuario" : auth.id,
            "pago" : pago,
            "cliente" : nomCliente,
          }
        //console.log(formdata);
        setIsLoading(true);
        const response = await setVentaNuevaApi(formdata);
        setIsLoading(false);
        if(response.status){
            /*abrir en una ventana nueva el pdf y actualizar la pagina*/
            //window.open('/folio/'+response.idFolio);
            window.open('/folio/'+response.idFolio);
           
            //console.log(response.rutaArchivo);
            //window.open("http://localhost/mulso-back/"+response.rutaArchivo);
            window.location.reload();
        }else{
            toast.error(response.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                }); 
        }
    }
    const setPagar = (e) =>{
        document.getElementById('cambio').textContent = "Cambio $"+ String(e-total);
        setPago(e);
    }
    const handleClick = () =>{
        let me = this;
        timer = setTimeout(function() {
          if (!prevent) {
            confirmarCompra();
          }
          prevent = false;
        }, delay);
      }
    const handleDoubleClick = ()=> {
        clearTimeout(timer);
        prevent = true;
        //this.doDoubleClickAction();
        alert('Solo debes de dar un click no dos no tres no mas clicks en el botón de Aceptar Compra');
      }
    return (
        <div className="contenido">
            <div className="sub_contenidoCenter">
                <Container>
                  <ToastContainer
                    transition={Flip}
                    position="bottom-center"
                    limit={1}
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                {
                    isLoading ? (
                        <LoadingSpinner
                        showSpinner={isLoading}
                        />
                    ):(
                        <></>
                    )
                }
                 {
                  auth != null ?(
                    auth.rol == 1 ?(
                    <>
                    <Row>
                        <Col md={12}>
                            <div className='input-group'>
                                <Col md={3}>
                                    <Form.Label>Seleccionar Sucursal</Form.Label>
                                </Col>
                                <Col md={9}>
                                    <Select
                                        placeholder='Selecciona la sucursal'
                                        options={sucursales}
                                        styles={customStyles}
                                        name="selectSucursal"
                                        onChange={selectSucursal} />&nbsp;
                                </Col>
                            </div>
                        </Col>
                    </Row> 
                    </>                   
                    ):(
                      <p></p>
                    )
                    ):(
                      <p></p>
                    )
                }
                <Row>
                    <Col md={12}>
                        <div className='input-group'>
                            <Col md={3}>
                                <Form.Label>Nombre del Cliente</Form.Label>
                            </Col>
                            <Col md={9}>
                                <Form.Control placeholder="Cliente"
                                    id="nomCliente"
                                    name="cliente"
                                    type="text"
                                />&nbsp;
                            </Col>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='input-group'>
                        <Col md={3}>
                            <h5 id="totalGlobal1">Total</h5>
                        </Col>
                        <Col md={6}>
                            <InputGroup>
                                <h5>Pago con</h5>&nbsp;
                                <Form.Control placeholder="Pago con"   
                                id="pago"
                                name="pago"
                                type="number"
                                defaultValue={pago}
                                onChange={event => setPagar(event.target.value)}
                                />
                            </InputGroup>
                            
                        </Col>
                        <Col md={3}>
                            <h5 id="cambio"></h5>
                        </Col>
                    </div>
                </Row>
                {
                prodcutosA != null?(
                <>
                    <Row>
                        <Col md={12}>
                            <Form.Group>
                                <InputGroup>
                                 <Button onClick={prodcutoAdd} title="Agregar Producto" size="sm">Agregar Producto</Button>
                                    &nbsp;
                                 <Button  onDoubleClick={handleDoubleClick} onClick={handleClick} size="sm">Aceptar Compra</Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={12}>
                            <Table>
                                <Thead>
                                    <Tr style={{'background': '#eff2f7', 'color': '#6d7781'}}>
                                        <Th className="borderTabla">Seleccionar Producto</Th>
                                        <Th className="borderTabla">Cantidad</Th>
                                        <Th className="borderTabla">Precio Unidad</Th>
                                        <Th className="borderTabla">Existencia Producto</Th>
                                        <Th className="borderTabla">Total</Th>
                                        <Th className="borderTabla">Eliminar</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                {

                                    fventas.map((fventa, i) => (
                                    <>
                                        <Tr key={fventa['productos[]']} className={(i+1) %2 != 0?('fondoTabla'):('') }>
                                        <Td className="borderTabla">
                                            <Select
                                                id={'index'+i}
                                                placeholder='Selecciona un Producto'
                                                options={prodcutos}
                                                styles={customStyles}
                                                defaultValue={fventa['productos[]'] != null ? (
                                                    prodcutos[prodcutos.findIndex(p => p.value === fventa['productos[]'])]
                                                 ) : (
                                                     [{value:0,label:"Elige un Producto"}]
                                                 )}
                                                name="productos[]"
                                                className='productosC[]'
                                                onChange={e => handleOnChange(i,"productos[]", e.value)}
                                            />
                                            <Form.Control placeholder="Cantidad"
                                            id={'productoR'+i}
                                            defaultValue={0}   
                                            name="productoR[]"
                                            type="hidden"
                                            />
                                        </Td>
                                        <Td className="borderTabla">
                                            <Form.Control placeholder="Cantidad"
                                                id={'cantidad'+i}
                                                defaultValue={
                                                    fventa['cantidad[]'] != null ? (
                                                        fventa['cantidad[]']
                                                     ) : (
                                                         0
                                                     )
                                                }   
                                                name="cantidad[]"
                                                type="number"
                                                min={0}
                                                onChange={e => handleOnChange(i,"cantidad[]", e.target.value)}
                                                />
                                        </Td>
                                        <Td className="borderTabla">
                                        <Form.Group>
                                            <Form.Label id={"precioR"+i} className='precioR[]'>$0</Form.Label>
                                        </Form.Group>
                                        </Td>
                                        <Td className="borderTabla">
                                            <Form.Group>
                                                <Form.Label id={"importeR"+i} className='importeR[]'>0</Form.Label>
                                            </Form.Group>
                                        </Td>
                                        <Td className="borderTabla">
                                            <Form.Group>
                                                <Form.Label id={"totalR"+i} className='totalR[]'>$0</Form.Label>
                                            </Form.Group>
                                        </Td>
                                        <Td className="borderTabla">
                                            <Button variant="danger" onClick={e => handleOnRemove(i)}>Eliminar</Button>
                                        </Td>
                                        </Tr>
                                    </>
                                    ))
                                }
                                </Tbody>
                            </Table>
                        </Col>
                    </Row>
                    
                    </>
                    ):(
                        <></>
                    )
                }                   
                </Container>
                <br></br><br></br>
            </div>
        </div>
    )
}

export default Ventas;
