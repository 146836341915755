import React from "react";
import './App.css';
import Footer from "./componentes/cuerpo/Footer";
import Header from './componentes/cuerpo/Header'
import Rutas from "./router/Rutas";

function App() {
  return (
    <div className="App">
      <div className='contenedor'>
        <Header/>
          
          <Rutas/>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
