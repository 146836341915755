import React,{useCallback, useState,useEffect} from 'react';
//import AuthContext from '../../context/AuthContext';
import { getDatosUsers } from '../../api/Login';
import {map} from 'lodash';
import { Document, Page, PDFViewer, Text, View ,Image,StyleSheet } from '@react-pdf/renderer';

import {
  useParams,useNavigate
} from "react-router-dom";
import { Button ,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import { getVentaBySucursalApi } from '../../api/Ventas';
import { getTokenApi } from '../../api/Token';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactExport from "react-export-excel";
import LoadingSpinner from '../views/LoadingSpinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function CorteDia() {
    const [isLoading, setIsLoading] = useState(false);
    let params = useParams();
    const [verWeb,setVerWeb] = useState(true);
    const [verPdf,setVerPdf] = useState(false);
    const [auth,setAuth] = useState(null);
    const [ventas,setVentas] = useState(null);
    const [ventasTabla,setVentasTabla] = useState(null);
    const [fechaini,setFechaini] = useState("");
    const [fechafin,setFechafin] = useState("");
    const [busqueda,setBusqueda] = useState("");
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
    const [totalArticulo,setTotalArticulo] = useState(0);
    const [totalDinero,setTotalDinero] = useState(0);
    let navigate = useNavigate();
    
    /*obtener las ventas del dia*/
    const getVentaBySucursal = useCallback( async(buscar,page_number,fechaini1,fechafin1) => {
      //console.log(buscar);
      setBusqueda(buscar);
      const TOKEN = await getTokenApi();
      const formdata = {
          "busqueda": buscar,
          "token" : TOKEN,
          "id_sucursal":params.sucursalId,
          "fechaini":fechaini1,
          "fechafin":fechafin1,
        }
        //console.log(formdata);
        //setCurrPage(page_number);
        var pro = await getVentaBySucursalApi(formdata);
        //console.log(usuario.totalPaginas);
        //setTotalPaginas(pro.totalPaginas);
        setTotalArticulo(pro.totalArticulo);
        setTotalDinero(pro.totalDinero); 
        setVentasTabla(pro.ventaDia); 
        setIsLoading(false); 
    },[]);

   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
     const init = async() =>{
     const user = await getDatosUsers();
     //console.log(user);
      if(user === null || user.rol == 5){
        navigate("/login", { replace: true });
      }else{
        setIsLoading(true);
        setAuth(user);
        getVentaBySucursal('',1,'','');
      }
    }
    init();
  }, [])
  var ExportarPDF = async() =>{

  }
  var busquedaFuncion = () =>{
    setIsLoading(true);
    //console.log("fechas",fechafin,fechaini);
    getVentaBySucursal("",1,fechaini,fechafin)
  }
 
  return(
    <>
       <div className="contenido">
            <div className="sub_contenido">
                <div className="filtros_pag">
                  <Form.Group>
                    <InputGroup>
                      <Form.Control 
                            type="date" 
                            name="horaEntrada"
                            defaultValue={fechaini} 
                            placeholder="Entrada"
                            onChange={event => setFechaini(event.target.value)}
                      />&nbsp;   
                      <Form.Control 
                            type="date" 
                            name="horaSalida" 
                            defaultValue={fechafin} 
                            placeholder="Salida"
                            onChange={event => setFechafin(event.target.value)}
                      />&nbsp;
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Button title="ExportarPDF" onClick={busquedaFuncion} className="botonIcono botonNuevo showmessages">Buscar</Button>
                      &nbsp;&nbsp;
                    <Button title="ExportarPDF" onClick={() => {setVerWeb(true); setVerPdf(false)}  } className="botonIcono botonNuevo showmessages">Web</Button>
                      &nbsp;&nbsp;
                    <Button title="ExportarPDF" onClick={() => {setVerWeb(false); setVerPdf(true)}  } className="botonIcono botonNuevo showmessages">PDF</Button>
                    &nbsp;
                  </Form.Group>
                  <Form.Group>
                  <ExcelFile element={<Button>Descargar Corte</Button>}>
                    <ExcelSheet data={ventasTabla} name="Corte">
                        <ExcelColumn label="Clave Producto" value="clave_producto"/>
                        <ExcelColumn label="Descripcion" value="descripcion_producto"/>
                        <ExcelColumn label="En Existencia" value="cantidad_real"/>
                        <ExcelColumn label="Cantidad Vendida" value="ventaTotalDia"/>
                        <ExcelColumn label="Total $$" value="totalV"/>
                                             
                    </ExcelSheet>
                </ExcelFile>
                  </Form.Group>
                </div>
                {
                  isLoading ? (
                    <LoadingSpinner
                    showSpinner={isLoading}
                    />
                  ):
                  (
                    <>
                    </>
                  )
                }
                {
                  verWeb ? (
                    <>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th className="borderTabla">Clave Producto</Th>
                            <Th className="borderTabla">Descripcion</Th>
                            <Th className="borderTabla">En Existencia</Th>
                                {/*<th>Precio del Producto</th>*/}
                            <Th className="borderTabla">Cantidad Vendida</Th>
                            <Th className="borderTabla">Total $$</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {
                            ventasTabla != null &&(
                              map(ventasTabla,(ventat,index)=>(
                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                  <Td className="borderTabla">{ventat.clave_producto}</Td>
                                  <Td className="borderTabla">{ventat.descripcion_producto}</Td>
                                  <Td className="borderTabla">{ventat.cantidad_real}</Td>
                                  {/*<td>{ventat.precio_unidad_venta}</td>*/}
                                  <Td className="borderTabla">{ventat.ventaTotalDia}</Td>
                                  <Td className="borderTabla">${ventat.totalV}</Td>
                                </Tr>
                              ))
                            )
                          }
                          {
                            totalArticulo != 0 &&(
                            <>
                            <Tr className="fondoTabla">
                              <Td className="borderTabla"></Td>
                              <Td className="borderTabla"></Td>
                              <Td className="borderTabla">Total Articulos Vendidos</Td>
                              <Td className="borderTabla">{totalArticulo}</Td>
                              <Td className="borderTabla"></Td>
                            </Tr>
                            <Tr className="fondoTabla">
                              <Td className="borderTabla"></Td>
                              <Td className="borderTabla"></Td>
                              <Td className="borderTabla">Total Ventas</Td>
                              <Td className="borderTabla"></Td>
                              <Td className="borderTabla">${totalDinero}</Td>
                            </Tr>
                            </>
                            )
                          }
                         
                        </Tbody>
                      </Table>
                    </>
                  ):(
                    <>
                    </>
                  )
                }
                {
                  verPdf ? (
                    <>                    
                      <PDFViewer style={styles.page}>
                        <Document>
                          <Page size="A4" style={styles.section}>
                          <View style={styles.headerTabla}>
                              <Text style={styles.description}>Clave Producto</Text>
                              <Text style={styles.qty}>Descripcion</Text>
                              <Text style={styles.rate}>En Existencia</Text>
                              {/*<Text style={styles.rate}>Precio del Producto</Text>*/}
                              <Text style={styles.rate}>Cantidad Vendida</Text>
                              <Text style={styles.rate}>Total</Text>
                          </View>
                          {
                            ventasTabla != null && (
                              map(ventasTabla,(ventat,i)=>(
                                <View key={i} style={styles.containerTabla}>
                                   <Text style={styles.description}>{ventat.clave_producto}</Text>
                                   <Text style={styles.qty}>{ventat.descripcion_producto}</Text>
                                   <Text style={styles.rate}>{ventat.cantidad_real}</Text>
                                   {/*<Text style={styles.rate}>{ventat.precio_unidad_venta}</Text>*/}
                                   <Text style={styles.rate}>{ventat.ventaTotalDia}</Text>
                                   <Text style={styles.rate}>${ventat.totalV}</Text>
                                </View>
                              ))
                            )
                          }
                          {
                            totalArticulo != 0 &&(
                            <>
                            <View style={styles.containerTabla}>
                              <Text style={styles.description}></Text>
                              <Text style={styles.qty}></Text>
                              <Text style={styles.rate}>Total Articulos Vendidos</Text>
                              <Text style={styles.rate}>{totalArticulo}</Text>
                              <Text style={styles.rate}></Text>
                            </View>
                            <View style={styles.containerTabla}>
                              <Text style={styles.description}></Text>
                              <Text style={styles.qty}></Text>
                              <Text style={styles.rate}>Total Ventas</Text>
                              <Text style={styles.rate}></Text>
                              <Text style={styles.rate}>${totalDinero}</Text>
                            </View>
                            </>
                            )
                          }
                          
                          </Page>
                        </Document>
                      </PDFViewer>
                      
                    </>
                  ):(
                    <></>
                  )
                }
                
            </div>
            <div>
     
    </div>
        </div>
        <br></br><br></br>
    </>
  )
}
const borderColor = '#fffff';
const styles = StyleSheet.create({
    page: { width: "100%", height: "90vh"},
    section: {/*margin: 5 ,marginTop:20,marginBottom:30,*/
      paddingTop: 30,
      paddingBottom: 30,
      paddingHorizontal: 35,
    },
    container:{marginTop: 10,flex:1,flexDirection:"row"},

    containerTabla:{
      flexDirection: 'row',
      borderBottomColor: '#bff0fd',
   
      borderBottomWidth: 1,
      alignItems: 'center',
      //height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
     // flexGrow: 1,
      //justifyContent:"space-between"
    },
    headerTabla:{
      flexDirection: 'row',
      borderBottomColor: '#bff0fd',
      backgroundColor: '#bff0fd',
      borderBottomWidth: 1,
      alignItems: 'center',
      //height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
     // flexGrow: 1,
      //justifyContent:"space-between"
    },
    centrado:{
        justifyContent:"center",
        alignItems:"center"
    },
    columnas:{
      width: '30%',
      flexDirection:"column",
    },
    textoCentrado:{
      fontSize:"10",
    },
    cssimg:{
      width: 80,height: 80,
    },
    description: {
      width: '18%',
      fontSize:"10",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  qty: {
      width: '60%',
      fontSize:"10",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  rate: {
      width: '18%',
      fontSize:"10",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  descriptionColumna: {
    width: '18%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    alignItems: 'center',
      //height: 24,
      textAlign: 'center',
},
qtyColumna: {
    width: '60%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
},
rateColumna: {
    width: '18%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    alignItems: 'center',
      //height: 24,
      textAlign: 'center',
},
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
},
rowFooter: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  //height: 24,
  
  fontStyle: 'bold',
},
descriptionFooter: {
  width: '74%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingRight: 8,
  fontSize: 8,
},
totalFooter: {
  width: '15%',
  textAlign: 'right',
  paddingRight: 8,
  fontSize: 8,
},
  });
export default CorteDia;
