import React, {useState,useEffect,useCallback} from 'react';
import { Button ,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import { getTokenApi } from '../../api/Token';
import { getSucursalesApi, activarSucursalApi} from '../../api/Sucursal';
import { getDatosUsers } from '../../api/Login';
import {map} from 'lodash';
import {
  useNavigate
} from "react-router-dom";
import { saveSucursalApi } from '../../api/Sucursal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import './Sucursales.css';
import LoadingSpinner from '../views/LoadingSpinner';
function Sucursales(){
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    let navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [arregloSucursales,setArregloSucursales] = useState(null);
     /*modals*/
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

     const cerrarModal = () => setShowModal(false);
     const abrirModal = () => setShowModal(true);
    /*variables formulario*/
    const [idSucursal,setIdSucursal] = useState(0);
    const [claveValor,setClaveValor] = useState("");
    const [nombreSucursalValor,setNombreSucursalValor] = useState("");
    const [direccionValor,setDireccionValor] = useState("");
    const [telefonoValor,setTelefonoValor] = useState("");
    const [celularValor,setCelularValor] = useState("");
    const [activoValor,setActivoValor] = useState(0);

    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");

    const [busqueda,setBusqueda] = useState("");

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
    const [currentPage, setCurrentPage] = useState(1);
    /*Obtener Sucursales*/
    const getSucursales = useCallback( async(buscar,page_number) => {
        //console.log(buscar);
        setBusqueda(buscar);
        const TOKEN = await getTokenApi();
        //console.log(TOKEN);
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
          }
        setCurrPage(page_number);
        var sucursalR = await getSucursalesApi(formdata);
        if(!sucursalR.error){
             //console.log(usuario.totalPaginas);
            setTotalPaginas(sucursalR.totalPaginas); 
            setArregloSucursales(sucursalR.sucursales);  
        }
        setIsLoading(false);
    },[]);
     useEffect(() => {
        setIsLoading(true);
        getSucursales('',1);
    }, [getSucursales])

    var sucursalNueva = () => {
        setIdSucursal(0);
        setClaveValor("");
        setNombreSucursalValor("");
        setDireccionValor("");
        setTelefonoValor("");
        setCelularValor("");
        setTitulomodal("Nueva");
        setBotonModal("Agregar");
        handleShow();
     }
     var editarSucursal = (suc) => {
        setIdSucursal(suc.id_sucursal);
        setClaveValor(suc.clave_sucursal);
        setNombreSucursalValor(suc.nombre_sucursal);
        setDireccionValor(suc.direccion_sucursal);
        setTelefonoValor(suc.telefono_sucursal);
        setCelularValor(suc.celular_sucursal);
        setTitulomodal("Editar");
        setBotonModal("Editar");
        handleShow();
     }
     var activarSucursal = (suc) => {
        setIdSucursal(suc.id_sucursal);
          if (suc.estado_sucursal == 1) {
            setActivoValor(0);
            setTitulomodal("Desactivar");
            setBotonModal("Desactivar");
            setMensajeModal("¿Esta seguro que desea desactivar la sucursal con clave "+suc.clave_sucursal+" ?");
          }else{
            setActivoValor(1);
            setTitulomodal("Activar");
            setBotonModal("Activar");
            setMensajeModal("¿Esta seguro que desea activar la sucursal con clave "+suc.clave_sucursal+" ?");
          }
          abrirModal();

     }
    var saveSucursal = async() =>{
        setIsLoading(true);
        var clave =  document.getElementById('clave').value;
        var nombreSucursal =  document.getElementById('nombreSucursal').value;
        var direccion =  document.getElementById('direccion').value;
        var telefono = document.getElementById('telefono').value;
        var celular = document.getElementById('celular').value;
        var idSucursal = document.getElementById('idSucursal').value;
        var errores = "";
        var error = 0;
        if(clave.toString() == ""){
            errores+="Debes ingresar una clave\n";
            error=1;
        }
        if(nombreSucursal.toString() == ""){
            errores+="Debes ingresar un nombre para la sucursal\n";
            error=1;
        }
        if(direccion.toString() == ""){
            errores+="Debes ingresar la dirección de la sucursal\n";
            error=1;
        }
        if(telefono.toString() == ""){
            errores+="Debes ingresar el telefono\n";
            error=1;
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "clave": clave,
                "nombreSucursal": nombreSucursal,
                "direccion": direccion,
                "telefono": telefono,
                "celular":celular,
                "token" : TOKEN,
                "idSucursal":idSucursal,
              }
            const response = await saveSucursalApi(formdata);
            if(response.status){
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    handleClose();
                    getSucursales(busqueda,1);
            }else{
                setIsLoading(false);
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    }); 
            }

        }else{
            setIsLoading(false);
            toast.error(errores, {
               position: "top-center",
               autoClose: 3000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "dark"
            }); 
        }
    
    }
    var activarDesactivarSucursal = async() =>{
        setIsLoading(true);
        var idSucursal = document.getElementById('idSucursalActivar').value;
        var estado_sucursal = document.getElementById('activoSucursal').value;
        //console.log(idUsuario,estadoUsuario);
        const TOKEN = await getTokenApi();
           const formdata = {
               "idSucursal":idSucursal,
               "token" : TOKEN,
               "estado_sucursal" : estado_sucursal,
             }
            //console.log(formdata);
            const resp = await activarSucursalApi(formdata);
             if(resp.status === "succes"){
               toast.success(resp.message, {
                   position: "top-center",
                   autoClose: 5000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
                   });
                   setShowModal(false);
                   getSucursales(busqueda,1);
           }else{
               setIsLoading(false);
               toast.error(resp.message, {
                   position: "top-center",
                   autoClose: 3000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
                   }); 
           }

    }
    var setBusquedaFuncion = (valor) =>{
        setIsLoading(true);
        getSucursales(valor,1);
     }
     function handlePageChange(page) {
        setIsLoading(true);
        setCurrentPage(page);
        //console.log("controler normal",page);
        getSucursales(busqueda,page);
        // ... do something with `page`
      }

    /*para la sesion*/
     /*Verificar si no ahy un usuario logueado*/
     /*Verificar si no ahy un usuario logueado*/
     useEffect(() => {
        const init = async() =>{
          const user = await getDatosUsers();
         // console.log(user);
          if(user === null){
            navigate("/login", { replace: true });
          }else{
            if (user.rol!=1) {
                navigate("/", { replace: true });
            } else {
                setAuth(user);
            }
          }
        }
        init();
    }, [])
 
    return(
        <>
       
        <div className="contenido">
        <div className="sub_contenido">
            <div className="filtros_pag">
                <Form.Group>
                    <InputGroup>
                        <Form.Control placeholder="Busqueda"   
                            id="busquedaInput"
                            name="busquedaInput"
                            type="text"
                            onChange={event => setBusquedaFuncion(event.target.value)}
                        />&nbsp;
                        <Button title="Nueva Sucursal" onClick={sucursalNueva} className="botonIcono botonNuevo showmessages">Nueva Sucursal</Button>
                        
                    </InputGroup>     
                </Form.Group>
            </div>
        {
            isLoading ? (
                <LoadingSpinner
                showSpinner={isLoading}
                />
            ):
            (
                <>
                </>
                )
            }
                
                <Table>
                    <Thead>
                        <Tr>
                        
                            <Th className="borderTabla">Clave</Th>
                            <Th className="borderTabla">Nombre de la Sucursal</Th>
                            <Th className="borderTabla">Dirección</Th>
                            <Th className="borderTabla">Teléfono</Th>
                            <Th className="borderTabla">Celular</Th>
                            <Th className="borderTabla">Configuración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                    {
                        arregloSucursales != null &&
                        (
                            map(arregloSucursales,(suc,index)=>(
                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                
                                    <Td className="borderTabla">{suc.clave_sucursal}</Td>
                                    <Td className="borderTabla">{suc.nombre_sucursal}</Td>
                                    <Td className="borderTabla">{suc.direccion_sucursal}</Td>
                                    <Td className="borderTabla">{suc.telefono_sucursal}</Td>
                                    <Td className="borderTabla">{suc.celular_sucursal}</Td>
                                    <Td className="borderTabla">
                                        <div className="configuracionBotones">
                                        <Button title="Editar Sucursal" onClick={() => editarSucursal(suc)}  className='botonEditar transparente'></Button>
                                        <Button onClick={() => activarSucursal(suc)} title={ suc.estado_sucursal != 1 ? ('Activar'):('Desactivar') } className={ suc.estado_sucursal != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                        <a title="Ver Inventario" href={`/sucursal/${suc.id_sucursal}`} className="botonView"></a>  
                                        <a title="Corte del Dia" href={`/corte/${suc.id_sucursal}`} className="botonVenta"></a>  
                                        <a title="Ventas del Día" href={`/ventasSucursal/${suc.id_sucursal}`} className="ticketBoton"></a>  
                                        <a title="Historial Cantidad" href={`/historialPrecios/${suc.id_sucursal}`} className="preciosBoton"></a>  

                                        </div>
                                    </Td>
                                </Tr>
                            ))
                        )
                    }

                    </Tbody>
                </Table>
                &nbsp;
                <div className="alignDerecha">
                    {
                        arregloSucursales != null ?
                        ( 
                        <Pagination
                            total={totalPaginas}
                            current={currPage}
                            onPageChange={page => handlePageChange(page)}
                        />
                    ):
                    (
                        <p>No hay registros</p>
                    )
                    }
                </div>
        
            
            <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />

        </div>
    </div>
    <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{tituloModal} Sucursal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                    <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Clave</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="nombre"   
                             id="idSucursal"
                             name="idSucursal"
                             type="hidden"
                             defaultValue={idSucursal}
                             />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control placeholder="clave"   
                             id="clave"
                             name="clave"
                             type="text"
                             defaultValue={claveValor}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Nombre de la Sucursal</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Nombre"   
                             id="nombreSucursal"
                             name="nombreSucursal"
                             type="text"
                             defaultValue={nombreSucursalValor}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Dirección</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Dirección"   
                             id="direccion"
                             name="direccion"
                             type="text"
                             defaultValue={direccionValor}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Número de Teléfono</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="123-456-7890"   
                             id="telefono"
                             name="telefono"
                             type="tel"
                             pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                             defaultValue={telefonoValor}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Número de Celular</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="123-456-7890"   
                             id="celular"
                             name="celular"
                             type="tel"
                             pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                             defaultValue={celularValor}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={saveSucursal} variant="primary"  id="botonModal">
                        {botonModal}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
        </Modal>

        <Modal  show={showModal} onHide={cerrarModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title id="tituloModal">{tituloModal} Sucursal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
            <Form.Group>
                <Form.Control placeholder="nombre"   
                    id="idSucursalActivar"
                    name="idSucursal"
                    type="hidden"
                    defaultValue={idSucursal}
                />
            </Form.Group>
            <Form.Group>
                <Form.Control placeholder="nombre"   
                    id="activoSucursal"
                    name="activoSucursal"
                    type="hidden"
                    defaultValue={activoValor}
                />
            </Form.Group>
             <h4>{mensajemodal}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={activarDesactivarSucursal} id="botonModal">
            {botonModal}
          </Button>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </>
    )
}
export default Sucursales;