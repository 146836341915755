import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import Select from 'react-select';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import logoUsuario  from "../../assets/logos/usuario.png"
import { getDatosUsers } from '../../api/Login';
import { useNavigate } from "react-router-dom";
import { getRolesApi,getUsuariosApi,saveUsuarioApi,activarUsuarioApi, resetUserApi, getUsuariosBySucursalApi } from '../../api/Usuarios';
import { getSelectSucursalesApi } from '../../api/Sucursal';
import { getTokenApi } from '../../api/Token';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
const diasOptions = [
    { value: 'Lunes', label: 'Lunes' },
    { value: 'Martes', label: 'Martes' },
    { value: 'Miercoles', label: 'Miercoles' },
    { value: 'Jueves', label: 'Jueves' }, 
    { value: 'Viernes', label: 'Viernes' }, 
    { value: 'Sabado', label: 'Sabado' },
    { value: 'Domingo', label: 'Domingo' }
  ];

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? 'green' : 'white',
    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }

function UsuariosSucursal(){
    const [auth, setAuth] = useState(null);
    let navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalResetear, setShowModalResetear] = useState(false);
    const [usuarios, setUsuarios] = useState(null);
    /*inputs selects*/
    const [rol, setRoles] = useState(null);
    const [sucursal,setSucursales] = useState(null);
    const [valorSelectRol,setValorSelectRol] = useState(null);
    const [valorSelectScursal,setValorSelectScursal] = useState(null);
    const [defaultSelectRol,setDefaultValorSelectRol] = useState(null);
    const [defaultSelectScursal,setDefaultValorSelectScursal] = useState(null);
    //inputs
    const [dias,setDiasValor] = useState(null);
    const [idUsuario,setIdUsuarioValor] = useState(0);
    const [nombre,setNombreValor] = useState("");
    const [nomUser,setNomUserValor] = useState("");
    const [horaEntrada,setHoraEntrada] = useState("");
    const [horaSalida,setHoraSalida] = useState("");
    const [password,setPassword] = useState("");
    const [passwordRepeat,setPasswordRepeat] = useState("");
    const[activoUsuario,setActivoUsuario] = useState(0);
    const [resetUsuario,setResetUsuario] = useState(0);
    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    /*modals*/
    const [busqueda,setBusqueda] = useState("");

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 

    const [idSucursal,setIdSucursal] = useState(0);
    /*obtener a los usuarios*/
    const getUsuarios = useCallback( async(buscar,page_number,sucursal) => {
        //console.log(buscar);
        setBusqueda(buscar);
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
            "idSucursal":sucursal,
          }
        setCurrPage(page_number);
        var usariosR = await getUsuariosBySucursalApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(usariosR.totalPaginas); 
        setUsuarios(usariosR.usuarios);  
    },[]);

     useEffect(() => {
        getUsuarios('',1,idSucursal);
    }, [getUsuarios])
    /**modals */
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true); 

     const cerrarModal = () => setShowModal(false);
     const abrirModal = () => setShowModal(true);

     const cerrarModalReset = () => setShowModalResetear(false);
     const abrirModalReset = () => setShowModalResetear(true);
     useEffect(()=>{
        (async()=>{
            const TOKEN = await getTokenApi();
            const formdata = {
                "token" : TOKEN,
            }
            const roles = await getRolesApi(formdata);
            var option = [];
            if(roles.error == null){
                roles.roles.map((role,index)=>(
                    option.push({
                        value:role.id_rol,
                        label:role.desc_rol
                    })             
                ))
                setRoles(option);
            }
            option = [];
            const sucursal = await getSelectSucursalesApi(formdata);
            if(sucursal.error == null){
                sucursal.sucursales.map((suc,index)=>(
                    option.push({
                        value:suc.id_sucursal,
                        label:suc.clave_sucursal+" "+suc.nombre_sucursal
                    })
                ));
                setSucursales(option);
            }
            
          })();
    },[]);
     var setBusquedaFuncion = (valor) =>{
        getUsuarios(valor,1,idSucursal);
     }
     function handlePageChange(page) {
        setCurrPage(page);
        //console.log("controler normal",page);
        getUsuarios(busqueda,page,idSucursal);
        // ... do something with `page`
      }
    /*Verificar si no ahy un usuario logueado*/
    useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        console.log(user);
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            if (user.rol!=1 && user.rol !=4) {
                navigate("/", { replace: true });
            } else {
                setAuth(user);
                setIdSucursal(user.usuario_sucursal);
                getUsuarios("",1,user.usuario_sucursal);
            }
        }
        }
        init();
    }, [])
    var resetearUsuario = async(userActivar)=> {
        //console.log(userActivar);
        setIdUsuarioValor(userActivar.id_usuario);
        if (userActivar.is_login == 1) {
            setActivoUsuario(0);
            setTitulomodal("Activar Sesion de usuario");
            setBotonModal("Activar Sesion");
            setMensajeModal("¿Esta seguro que desea activar la sesion del usuario "+userActivar.nombre_usuario+" ?");
          }else{
            setActivoUsuario(1);
            setTitulomodal("Activar");
            setBotonModal("Activar");
            setMensajeModal("¿Esta seguro que desea desactivar la sesion del usuario "+userActivar.nombre_usuario+" ?");
          }
          abrirModalReset();
    }
    var resetUser = async() => {
        const TOKEN = await getTokenApi();
        const formdata = {
            "idUsuario":idUsuario,
            "token" : TOKEN,
            "estado_usuario" : activoUsuario,
          }
        // console.log('activarDesactivarUsuario',formdata);
         const resp = await resetUserApi(formdata);
          if(resp.status === "succes"){
            toast.success(resp.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                setShowModalResetear(false);
                getUsuarios(busqueda,1,idSucursal);
        }else{
            toast.error(resp.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                }); 
        }
    }
    return (
        <>
        <div className="contenido">
            <div className="sub_contenido">
                <div className="filtros_pag">
                    <Form.Group>
                        <InputGroup>
                            <Form.Control placeholder="Busqueda"   
                                id="busquedaInput"
                                name="busquedaInput"
                                type="text"
                                onChange={event => setBusquedaFuncion(event.target.value)}

                            />
                            &nbsp;
                        </InputGroup>
                        
                    </Form.Group>
                    
                </div>
                <Table>
                    <Thead>
                        <Tr>
                            <Th className="borderTabla"></Th>
                            <Th className="borderTabla">Nombre de Usuario</Th>
                            <Th className="borderTabla">Nombre</Th>
                            <Th className="borderTabla">Días</Th>
                            <Th className="borderTabla">Horario</Th>
                            <Th className="borderTabla">Rol</Th>
                            <Th className="borderTabla">Sucursal</Th>
                            <Th className="borderTabla">Configuración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                {
                    usuarios != null &&
                    (
                        map(usuarios,(user,index)=>(
                            <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                <Td className="borderTabla"><img src={logoUsuario} className="imgUsuarios" alt=""/></Td>
                                <Td className="borderTabla">{user.user_usuario}</Td>
                                <Td className="borderTabla">{user.nombre_usuario}</Td>
                                <Td className="borderTabla">
                                 {
                                    user.dias_trabajo_usuario != null &&
                                    map(JSON.parse(user.dias_trabajo_usuario),(diasTrabajo)=>(
                                      <label key={diasTrabajo.value}>{diasTrabajo.label} &nbsp;</label>
                                    ))
                                 }
                                </Td>
                                <Td className="borderTabla">{user.hora_trabajo_entrada_usuario} a {user.hora_trabajo_salida_usuario}</Td>
                                <Td className="borderTabla">{user.desc_rol}</Td>
                                <Td className="borderTabla">{user.clave_sucursal} {user.nombre_sucursal}</Td>
                                <Td className="borderTabla">
                                    <div className="configuracionBotones">
                                      <Button onClick={() => resetearUsuario(user)} title={ user.is_login != 0 ? ('Activar Sesion'):('Desactivar Sesion') } className={ user.is_login != 0 ? ('botonBlock transparente'):('botonActivelog transparente') } type='button' name='button'></Button>
                                      <a title="Folios Entregados" href={`/ticketsUsuarios/${user.id_usuario}`} className="ticketBoton"></a>  
                                    </div>
                                </Td>
                            </Tr>
                        ))
                    )
                }
                    </Tbody>
                </Table>
                &nbsp;						
                <div className="alignDerecha">
                {
                    usuarios != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                    ):
                    (
                    <p>No hay registros</p>
                    )
                }
                </div>
                <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
            </div>
        </div>
        <Modal  show={showModalResetear} onHide={cerrarModalReset} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="idUsuarioActivar"
                        name="idUsuario"
                        type="hidden"
                        defaultValue={idUsuario}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="activoUsuario"
                        name="activoUsuario"
                        type="hidden"
                        defaultValue={activoUsuario}
                    />
                </Form.Group>
                <h4>{mensajemodal}</h4>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={resetUser} id="botonModal">
                {botonModal}
            </Button>
            <Button variant="secondary" onClick={cerrarModalReset}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
        </>
 
    )
}
export default UsuariosSucursal;