import { API_URL } from "../utils/constant";
import { getTokenApi } from "./Token";

export async function setVentaNuevaApi(formdata){
    try {
        const url= `${API_URL}Ventas/setVentaNueva`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getVentaByFolioIdApi(formdata){
    try {
        const url= `${API_URL}Ventas/getVentaByFolioId`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getVentaByFolioApi(formdata){
    try {
        const url= `${API_URL}Ventas/getVentaByFolio`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getVentaBySucursalApi(formdata){
    try {
        const url= `${API_URL}Ventas/getVentaBySucursal`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function getVentaBySucursalByIdApi(formdata){
    try {
        const url= `${API_URL}Ventas/getVentaBySucursalById`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export async function cancelarFolioByIdApi(formdata){
    try {
        const url= `${API_URL}Ventas/cancelarFolioById`;
        const params = {
            method: 'POST',
            headers: {
                'Accept':'application/json',
               'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(formdata),
        };
            //console.log(params);
        const responde = await fetch(url,params);
        const result = await responde.json();
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
}
