import React, {useCallback,useState, useMemo, useContext,useEffect} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import Select from 'react-select';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import {
  useNavigate,
  Link,
} from "react-router-dom";
import { getDatosUsers } from '../../api/Login';
import { getTokenApi } from '../../api/Token';
import { getProductosApi, saveProductoApi } from '../../api/Producto';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
const tipo = [
    { value: 1, label: 'Caja' },
    { value: 2, label: 'Piezas Sueltas' }
  ];

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? 'green' : 'white',
    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }
function Productos(){
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    let navigate = useNavigate();
    const [productos, setProductos] = useState(null);
    const [numPorPagina,setNumPorPagina] = useState(20);

    const [idProducto,setIdProducto] = useState(null);
    const [clave,setClave] = useState(null);
    const [descripcion,setDescripcion] = useState(null);
    const [valorSelectProducto,setValorSelectProducto] = useState(null);
    const [defaultTipoProducto,setDefaultTipoProducto] = useState(null);
    const [precio,setPrecio] = useState(null);
    const [cantidad,setCantidad] = useState(null);
   
   
    const [productosCaja,setProductosCaja] = useState(null);
    const [defaultTipoProductoCaja,setDefaultTipoProductoCaja] = useState(null);
    const [valorProductoCaja,setValorProductoCaja] = useState(null);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    const [busqueda,setBusqueda] = useState("");

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
    /*obtener a los usuarios*/
    const getProductos = useCallback( async(buscar,page_number,numPorPag) => {
      //console.log(buscar);
       setBusqueda(buscar);
       const TOKEN = await getTokenApi();
       
       const formdata = {
           "busqueda": buscar,
           "token" : TOKEN,
           "pagina" : page_number,
           "numPorPagina":numPorPag,
        }
        setCurrPage(page_number);
        var pro = await getProductosApi(formdata);
        if(!pro.error){
            setTotalPaginas(pro.totalPaginas); 
            setProductos(pro.productos);  
        }
        //console.log(usuario.totalPaginas);
        setIsLoading(false);
    },[]);

     useEffect(() => {
        setIsLoading(true);
        getProductos('',1,numPorPagina);
    }, [getProductos]) 
    /*modals*/
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true); 
 
     var multipleSelectProducto = (e) =>{
        setValorSelectProducto(e.value);
        //console.log(e);
    }
    var multipleSelectProductoCaja = (e) =>{
        setValorProductoCaja(e.value);
        //console.log(e);
    }

    var productoNuevo = () => {
        setIdProducto(0);
        setClave("");
        setDescripcion("");
        setPrecio("");
        setCantidad("");
        setDefaultTipoProducto({value:0,label:"Selecciona el tipo de Producto"});
        setDefaultTipoProductoCaja({value:0,label:"Selecciona un correspondiente"});
        setValorProductoCaja("");
        setTitulomodal("Añadir");
        setBotonModal("Agregar");
        handleShow();
     }
    var editarProducto = (proEdit) =>{
        //console.log(proEdit);
        setIdProducto(proEdit.id_producto);
        setClave(proEdit.clave_producto);
        setDescripcion(proEdit.descripcion_producto);
        setPrecio("");
        setCantidad("");
        setDefaultTipoProductoCaja();
        setValorProductoCaja("");
        map(tipo,(t) =>{
            if (t.value == proEdit.tipo_producto) {
                setDefaultTipoProducto(t);
                multipleSelectProducto(t);
            }
        });

        setTitulomodal("Editar");
        setBotonModal("Editar");
         handleShow();
    }
    var saveProducto = async() =>{
        setIsLoading(true);
        var errores = "";
        var error = 0;
        if(idProducto.toString() == ""){
            errores+="Algo ocurrio recarga la pagina\n";
            error=1;
        }
        if(clave.toString() == ""){
            errores+="Debes ingresar la clave del producto\n";
            error=1;
        }
        if(descripcion.toString() == ""){
            errores+="Debes ingresar la descripción del producto\n";
            error=1;
        }
        if(Array.isArray(valorSelectProducto) && valorSelectProducto.length== 0){
            errores+="Debes de seleccionar el tipo de producto\n";
            error=1;
        }else{
            if(valorSelectProducto == null){
                errores+="Debes de seleccionar el tipo de producto\n";
                error=1;
            }
        }
        if(precio.toString() != "" && parseInt(precio) <= 0){
            errores+="Si deseas agregar un precio para usarlo en general no debe de ser 0 o menor a 0\n";
            error=1;
        }
        if(cantidad.toString() != "" && parseInt(cantidad) <= 0){
            errores+="Si deseas agregar una cantidad para usarlo en general no debe de ser 0 o menor a 0\n";
            error=1;
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "idProducto":idProducto,
                "token" : TOKEN,
                "clave_producto" : clave,
                "descripcion_producto":descripcion,
                "tipo_producto" : valorSelectProducto,
                "correspondiente_producto" : valorProductoCaja,
                "precio_producto_sucursal" : precio,
                "existencia_producto_sucursal" : cantidad
              }
            // console.log('activarDesactivarUsuario',formdata);
             const resp = await saveProductoApi(formdata);
              if(resp.status === "succes"){
                toast.success(resp.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    setShow(false);
                    getProductos(busqueda,1,numPorPagina);
            }else{
                toast.error(resp.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    }); 
                setIsLoading(false);
            }
        }else{
            toast.error(errores, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
             }); 
             setIsLoading(false);
        }
    }
    var setBusquedaFuncion = (valor) =>{
        setIsLoading(true);
        setBusqueda(valor);
        getProductos(valor,1,numPorPagina);
     }
     var setPorPagina = (valor) =>{
        setIsLoading(true);
        //console.log(valor);
        setNumPorPagina(valor);
        getProductos(busqueda,1,valor);
     }
     function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        getProductos(busqueda,page,numPorPagina);
        // ... do something with `page`
      }

     /*para la sesion*/
     /*Verificar si no ahy un usuario logueado*/
     useEffect(() => {
        const init = async() =>{
          const user = await getDatosUsers();
          if(user === null){
            navigate("/login", { replace: true });
          }else{
            if (user.rol!=1) {
                navigate("/", { replace: true });
            } else {
                setAuth(user);
            }
          }
        }
        init();
    }, [])
    return(
        <>
       
        <div className="contenido">
        <div className="sub_contenido">
           
            <div className="filtros_pag">
                <Form.Group>
                    <InputGroup>    
                        <Form.Label>Mostrar</Form.Label>&nbsp;
                        <Form.Control onChange={event => setPorPagina(event.target.value)} as="select">
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="70">70</option>
                            <option value="100">100</option>
                        </Form.Control>&nbsp;
                        <Form.Label>registros por página</Form.Label>&nbsp;
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="Busqueda"   
                        id="busquedaInput"
                        name="busquedaInput"
                        type="text"
                        onChange={event => setBusquedaFuncion(event.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                  <Button title="Nuevo Producto" onClick={productoNuevo} className="botonIcono botonNuevo showmessages">Nuevo Producto</Button>
                </Form.Group>
            </div>
            {
            isLoading ? (
                <LoadingSpinner
                showSpinner={isLoading}
                />
            ):
            (
                <>
                </>
                )
            }
            <Table>
                <Thead>
                    <Tr>
                        <Th className="borderTabla">Clave</Th>
                        <Th className="borderTabla">Descripcion</Th>
                        <Th className="borderTabla">Tipo de Producto</Th>
                        {
                        /*
                            <th>Sucursales</th>
                            <th>Correspondiente</th>
                        */
                        }
                        <Th className="borderTabla">Configuración</Th>
                    </Tr>
                    </Thead>
                <Tbody>
                    {
                        productos != null &&
                        (
                            map(productos,(pro,index)=>(
                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                    <Td className="borderTabla">{pro.clave_producto}</Td>
                                    <Td className="borderTabla">{pro.descripcion_producto}</Td>
                                    <Td className="borderTabla">
                                        {
                                            pro.tipo_producto == 1 ?(
                                                <p>Caja</p>
                                            ):(
                                                <p>Piezas Sueltas</p>

                                            )
                                        }
                                    </Td>
                                    {
                                    /*
                                    <td>Sucursales</td>
                                    <td>Correspondiente</td>
                                    */
                                    }
                                   <Td className="borderTabla">
                                        <div className="configuracionBotones">
                                            <Button title="Editar Producto" onClick={() => editarProducto(pro)}  className='botonEditar transparente'></Button>
                                            <a href={`/productos/${pro.id_producto}`} className="botonView transparente"></a>  
                                        </div>
                                    </Td>
                      
                                </Tr>
                            ))
                        )
                    }
               </Tbody>
            </Table>
            &nbsp;
            <div className="alignDerecha">
                {
                    productos != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                ):
                (
                    <p>No hay registros</p>
                )
                }
            </div>
            <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
        </div>
    </div>
    <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{tituloModal} Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Clave</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="nombre"   
                             id="idProducto"
                             name="idProducto"
                             type="hidden"
                             defaultValue={idProducto}
                             />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control placeholder="Clave Producto"   
                             id="clave"
                             name="clave"
                             type="text"
                             defaultValue={clave}
                             onChange={event => setClave(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Descripción</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Descripción"   
                             id="descripcion"
                             name="descripcion"
                             type="text"
                             defaultValue={descripcion}
                             onChange={event => setDescripcion(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Tipo</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Select
                               placeholder='pieza o caja'
                               options={tipo}
                               styles={customStyles}
                               id="sucursalSelect"
                               defaultValue={defaultTipoProducto}
                               onChange={multipleSelectProducto} />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      {
                        idProducto != 0 ?(
                            <></>
                        ):(
                          <>
                          <Row className='centrarTexto'>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>Los campos de precio y cantidad pueden ir en blanco</Form.Label>
                                </Form.Group>
                            </Col>
                          </Row>
                          <br></br>
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Precio</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={9}>
                                <Form.Group>
                                    <Form.Control placeholder="Precio"   
                                    id="precio"
                                    name="precio"
                                    type="number"
                                    min="0"
                                    defaultValue={precio}
                                    onChange={event => setPrecio(event.target.value)}
                                    />
                                </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Cantidad</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col md={9}>
                                <Form.Group>
                                    <Form.Control placeholder="Cantidad Producto"   
                                    id="cantidad"
                                    name="cantidad"
                                    type="number"
                                    defaultValue={cantidad}
                                    onChange={event => setCantidad(event.target.value)}
                                    />
                                </Form.Group>
                                </Col>
                            </Row>
                            <br></br>
                          </>
                        )
                      }
                      
                      {/*
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Producto Correspondiente</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Select
                               placeholder='producto'
                               options={productosCaja}
                               styles={customStyles}
                               id="sucursalSelect"
                               defaultValue={defaultTipoProductoCaja}
                               onChange={multipleSelectProductoCaja} />
                        </Form.Group>
                        </Col>
                      </Row>*/
                      }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveProducto} id="botonModal">
                         {botonModal}    
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
        </Modal>
    </>
    )
}
export default Productos;