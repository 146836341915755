import React,{useCallback, useState,useEffect} from 'react';
import { getDatosUsers } from '../../api/Login';
import { getTokenApi } from '../../api/Token';
import { getVentaByFolioIdApi } from '../../api/Ventas';
import {map} from 'lodash';
import {
    Navigate,useNavigate,useParams 
  } from "react-router-dom";
import './Folio.css';
//import QRCode from 'react-qr-code';
import QRCode  from 'qrcode';
import { Document, Page, PDFViewer, Text, View ,Image,StyleSheet } from '@react-pdf/renderer';
function Folio() {
  const [auth, setAuth] = useState(null);
  const [data, setData] = useState(null);
  const [qrvalor,setQrvalor] = useState("");
  let params = useParams();
  let navigate = useNavigate();
  const getVentaByFolioId = useCallback( async() => {
    const TOKEN = await getTokenApi();
    const formdata = {
        "idFolio": params.folioId,
        "token" : TOKEN,
      }
    var option = [];
    var venta = await getVentaByFolioIdApi(formdata);
    QRCode.toDataURL(venta.ventaFolio[0]['numero_folio_enc']).then((dataqr)=>{
        setQrvalor(dataqr);
    })
    setData(venta.ventaFolio);
    //setQrvalor(venta.ventaFolio[0]['numero_folio_enc']); 
   
},[]);
  useEffect(() => {
    getVentaByFolioId();
}, [getVentaByFolioId]) 
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
      //console.log(user);
      if(user === null){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
      }
    }
    init();
  }, [])
  return(
    <>
        <div>

        
        {
            data != null ?(
                <>
                <PDFViewer style={styles.page}>
                  <Document>
                    <Page size="A4" style={styles.section}>
                      <View style={styles.container}>
                        <View style={styles.columnas}>
                          <View style={styles.centrado}>
                            <Text style={styles.textoCentrado}>MULSO (Multimarcas Soto) </Text>
                            <Text style={styles.textoCentrado}>{data[0]['clave_sucursal']} {data[0]['nombre_sucursal']}</Text>
                            <Text style={styles.textoCentrado}>{data[0]['direccion_sucursal']}</Text>
                            <Text style={styles.textoCentrado}>Tel: {data[0]['telefono_sucursal']}</Text>
                            <Text style={styles.textoCentrado}>Ticket de venta {data[0]['numero_folio']}</Text>  
                            <Text style={styles.textoCentrado}>{data[0]['fecha_folio']}</Text> 
                            <Text style={styles.textoCentrado}>Cliente: {data[0]['nombre_cliente_folio']}</Text> 
                            <Text style={styles.textoCentrado}>Cajero: {data[0]['nombre_usuario']}</Text> 
                            <View style={styles.headerTabla}>
                                <Text style={styles.description}>CANT</Text>
                                <Text style={styles.qty}>Descripcion</Text>
                                <Text style={styles.precioUnitarioqty}>P.Unit.</Text>
                                <Text style={styles.rate}>Total</Text>
                            </View>
                            { 
                              data != null &&(
                                map(data,(datos,i) =>(
                                  <View key={i} style={styles.containerTabla}>
                                   <Text style={styles.descriptionColumna}>{datos['cantidad_venta']}  </Text>
                                   <Text style={styles.qtyColumna}>{datos['descripcion_producto']}</Text>
                                   <Text style={styles.precioUnitarioColumna}>${datos['precio_unidad_venta']} </Text>
                                   <Text style={styles.rateColumna}>${datos['total_venta']}  </Text>
                                  </View>
                                ))
                              )
                            }
                            <View style={styles.rowFooter}>
                                <Text style={styles.descriptionFooter}>Articulos Comprados</Text>
                                <Text style={styles.totalFooter}>{ data[0]['articulos_comprados']}</Text>
                            </View>
                            <View style={styles.rowFooter}>
                                <Text style={styles.descriptionFooter}>TOTAL</Text>
                                <Text style={styles.totalFooter}>${ data[0]['total_vendio_folio']}</Text>
                            </View>
                            <View style={styles.rowFooter}>
                                <Text style={styles.descriptionFooter}>Pago con</Text>
                                <Text style={styles.totalFooter}>${ data[0]['pago_con_ventas']}</Text>
                            </View>
                            <View style={styles.rowFooter}>
                                <Text style={styles.descriptionFooter}>Su cambio</Text>
                                <Text style={styles.totalFooter}>${data[0]['cambio_ventas']}</Text>
                            </View>
                            <Image style={{ width: 100,height: 100}} source={qrvalor}/>
                            <Text style={styles.textoCentrado}>¡GRACIAS POR SU COMPRA!</Text>
                            <Text style={styles.textoCentrado}>www.mulso.com.mx</Text>

                          </View>              
                        </View>
                        <View style={styles.columnas}>

                        </View>
                        <View style={styles.columnas}>

                        </View>
                      </View>
                    </Page>
                  </Document>
                </PDFViewer></>
                
            ):(
                <p></p>
            )
        }
        </div>
   
    </>
  )
}
const borderColor = '#fffff';
const styles = StyleSheet.create({
    page: { width: "100%", height: "90vh",},
    section: {margin: 5 ,},
    container:{marginTop: 10,flex:1,flexDirection:"row"},

    containerTabla:{
      flexDirection: 'row',
      flexWrap: 'wrap',
      //marginTop: 20,
      borderWidth: 1,
      borderColor: '#bff0fd',
    },
    headerTabla:{
      marginTop: 10,
      flexDirection: 'row',
      borderBottomColor: '#bff0fd',
      backgroundColor: '#bff0fd',
      borderBottomWidth: 1,
      alignItems: 'center',
      //height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
     // flexGrow: 1,
      //justifyContent:"space-between"
    },
    centrado:{
        justifyContent:"center",
        alignItems:"center"
    },
    columnas:{
      width: '30%',
      flexDirection:"column",
    },
    textoCentrado:{
      fontSize:"8",
    },
    cssimg:{
      width: 80,height: 80,
    },
    description: {
      width: '10%',
      fontSize:"8",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  qty: {
      width: '45%',
      fontSize:"8",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  precioUnitarioqty: {
    width: '15%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
      width: '20%',
      fontSize:"8",
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  descriptionColumna: {
    width: '10%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    alignItems: 'center',
      //height: 24,
      textAlign: 'center',
},
qtyColumna: {
    width: '45%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
},
precioUnitarioColumna: {
  width: '15%',
  fontSize:"8",
  borderRightColor: borderColor,
  borderRightWidth: 1,
},
rateColumna: {
    width: '20%',
    fontSize:"8",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    alignItems: 'center',
      //height: 24,
      textAlign: 'center',
},
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    //height: 24,
    fontStyle: 'bold',
},
rowFooter: {
  flexDirection: 'row',
  borderBottomColor: '#bff0fd',
  borderBottomWidth: 1,
  alignItems: 'center',
  //height: 24,
  
  fontStyle: 'bold',
},
descriptionFooter: {
  width: '73%',
  textAlign: 'right',
  borderRightColor: borderColor,
  borderRightWidth: 1,
  paddingRight: 8,
  fontSize: 8,
},
totalFooter: {
  width: '22%',
  textAlign: 'right',
  paddingRight: 8,
  fontSize: 8,
},
  });
export default Folio;
