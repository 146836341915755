import React,{ useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import { getDatosUsers } from '../../api/Login';
import {
  Navigate,useNavigate,useParams
} from "react-router-dom";
import { getVentaBySucursalByIdApi } from '../../api/Ventas';
import { getTokenApi } from '../../api/Token';
import Paginacion from '../paginacion/Paginacion';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
function FoliosEntregados() {
    let params = useParams();
    const [auth,setAuth] = useState(null);
    const [idUser,setIdUser] = useState(0);
    const [folios,setFolios] = useState(null);
    let navigate = useNavigate();
    const [busqueda,setBusqueda] = useState("");

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
    const [fechaini,setFechaini] = useState("");
    const [fechafin,setFechafin] = useState("");
    /*obtener a los usuarios*/
    const getFoliosEntregados = useCallback( async(buscar,page_number,id_user,fechainib,fechafinb) => {
        //console.log(buscar);
        setBusqueda(buscar);
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
            "id_sucursal" :id_user,
            "band":1,
            "estadoFolio":10,
            "fechaini":fechainib,
            "fechafin":fechafinb,
          }
        setCurrPage(page_number);
        var foliosEntregados = await getVentaBySucursalByIdApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(foliosEntregados.totalPaginas); 
        setFolios(foliosEntregados.folios);  
    },[]);
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
      //console.log(user);
      if(user === null || user.rol == 5){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
        if (params.idUser != null) {
            setIdUser(params.idUser);
            getFoliosEntregados("",1,params.idUser,fechaini,fechafin)
        } else {
            setIdUser(user.id);
            getFoliosEntregados("",1,user.id,fechaini,fechafin)
        }
       
      }
    }
    init();
  }, [])
  var setBusquedaFuncion = (busqueda) =>{
    //console.log(busqueda);
    getFoliosEntregados(busqueda,1,idUser,fechaini,fechafin);
  }
  function handlePageChange(page) {
    setCurrPage(page);
    //console.log("controler normal",page);
    getFoliosEntregados(busqueda,page,idUser,fechaini,fechafin);
    // ... do something with `page`
  }
  var setFechainiB = (valor) =>{
    setFechaini(valor)
    if(fechafin != ""){
        getFoliosEntregados(busqueda,1,idUser,valor,fechafin);
    }else{
      if (fechafin == "" && fechaini == "") {
        getFoliosEntregados(busqueda,1,idUser,"","");
      }
    }
 }
 var setFechafinB = (valor) =>{
  setFechafin(valor)
  if(fechaini != ""){
    getFoliosEntregados(busqueda,1,idUser,fechaini,valor);
  }else{
    if (fechafin == "" && fechaini == "") {
        getFoliosEntregados(busqueda,1,idUser,"","");
    }
  }
 }
  return(
    <>
     <div className="contenido">
        <div className="sub_contenido">
            <div className="filtros_pag">
                <Form.Group>
                  <InputGroup>
                    <Form.Control 
                      type="date" 
                      name="horaEntrada"
                      defaultValue={fechaini} 
                      placeholder="Entrada"
                      onChange={event => setFechainiB(event.target.value)}
                    />&nbsp;  
                    <Form.Control 
                      type="date" 
                      name="horaSalida" 
                      defaultValue={fechafin} 
                      placeholder="Salida"
                      onChange={event => setFechafinB(event.target.value)}
                    />&nbsp;
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="Busqueda"   
                        id="busquedaInput"
                        name="busquedaInput"
                        type="text"
                        onChange={event => setBusquedaFuncion(event.target.value)}
                    />
                </Form.Group>
               
           </div>
           <Table>
                <Thead>
                    <Tr>
                        <Th className="borderTabla">Folio</Th>
                        <Th className="borderTabla">Cliente</Th>
                        <Th className="borderTabla">Estado</Th>
                        <Th className="borderTabla">No. Articulos</Th>
                        <Th className="borderTabla">Total $$</Th>
                        <Th className="borderTabla">Fecha</Th>
                        <Th className="borderTabla">Configuración</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        folios != null &&
                        (
                          map(folios,(fol,index) =>(
                            <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                <Td className="borderTabla">{fol.numero_folio}</Td>
                                <Td className="borderTabla">{fol.nombre_cliente_folio}</Td>
                                <Td className="borderTabla">
                                    {
                                        fol.estado_folio == 0 ?(
                                            <p>En Espera</p>
                                        ):(
                                            fol.estado_folio == 1 ?(
                                                <p>Entregado</p>
                                            ):( 
                                                <p>Cancelado</p>
                                            )
                                        )
                                    }                               
                                </Td>
                                <Td className="borderTabla">{fol.articulos_comprados}</Td>
                                <Td className="borderTabla">{fol.total_vendio_folio}</Td>
                                <Td className="borderTabla">{fol.fecha_folio}</Td>
                                <Td className="borderTabla">
                                    <div className="configuracionBotones">
                                        <a title="Ticket" target="_blank" href={`/folio/${fol.id_folio}`} className="botonView"></a>
                                    </div>  
                                </Td>
                            </Tr>
                          ))
                        )
                    }
                </Tbody>
            </Table> 
            &nbsp;						
            <div className="alignDerecha">
                {
                    folios != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                ):
                (
                    <p>No hay registros</p>
                )
                }
            </div>   
        </div>
    </div>
    </>
  )
}

export default FoliosEntregados;
