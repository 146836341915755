import React from 'react';
import {Button} from "react-bootstrap";
import {
  useNavigate
} from "react-router-dom";
import PageNotFound from '../../assets/logos/Error404.png'
function NotFound() {
  let navigate = useNavigate();
  var irAHome = () =>{
    navigate('/');
  }
  return(
    <>
    <div className="contenido">
        <div className="sub_contenido">
            <div className='NotFounContent'>
              <div className='contenImagenNotFound'>
               <img alt='some value' src={PageNotFound} className="imagenNotFound" />
              </div>           
              <div className='textoNotFound'>
                <Button onClick={irAHome} variant="primary">Ir a la Pagina Principal</Button>
              </div>
          </div>
         
        </div>
    </div>
      
     </>
  )
}

export default NotFound;

