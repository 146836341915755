import React, {useCallback,useState,useEffect} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import {
    useNavigate,useParams 
  } from "react-router-dom";
import { getDatosUsers } from '../../api/Login';
import { getTokenApi } from '../../api/Token';
import { activarDesactivarPSApi, getAllSucursalProductoApi, updateProductoSucursalApi } from '../../api/ProductoSucursal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import ReactExport from "react-export-excel";
import LoadingSpinner from '../views/LoadingSpinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function SucursalGerente() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth,setAuth] = useState(null);
    let navigate = useNavigate();
    let params = useParams();
    const [sucursalUsuario,setSucursalUsuario] = useState(null);
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [numPorPagina,setNumPorPagina] = useState(20);

    const [idProductoSucursal,setidProductoSucursal] = useState(null);
    const [precio,setPrecio] = useState(null);
    const [cantidad,setCantidad] = useState(null);

    const [busqueda,setBusqueda] = useState("");
    const [productoSucursal,setProductoSucursal] = useState(null);
    const [activoPS,setaActivoPS] = useState(null);

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
  
   /**modals */
   const [tituloModal,setTitulomodal] = useState("");
   const [cambiarTipo,setCambiarTipo] = useState("");
   const [encabezadoModal,setEncabezadoModal] = useState("");
   const [accion,setAccion] = useState(0);
   const [motivo,setMotivo] = useState("");
   const [botonModal,setBotonModal] = useState("");
   const [mensajemodal,setMensajeModal] = useState("");
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true); 

   const cerrarModal = () => setShowModal(false);
   const abrirModal = () => setShowModal(true);
   const [dataReal,setDataReal] = useState(null);
    /*obtener a los usuarios*/
    const getProductosSucursal = useCallback( async(buscar,page_number,sucursalId,numPorPag) => {
      //console.log(sucursalUsuario);
       setBusqueda(buscar);
       const TOKEN = await getTokenApi();
       const formdata = {
           "busqueda": buscar,
           "token" : TOKEN,
           "pagina" : page_number,
           "idSucursal":sucursalId,
           "numPorPagina":numPorPag,
        }
       // console.log(formdata);
        setCurrPage(page_number);
        var pro = await getAllSucursalProductoApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(pro.totalPaginas); 
        setProductoSucursal(pro.productoSucursal);
        setDataReal(pro.totalproductoSucursal);  
        setIsLoading(false); 
    },[]);

     /*useEffect(() => {
        getProductosSucursal('',1);
    }, [getProductosSucursal])*/ 
    var setBusquedaFuncion = (valor) =>{
        getProductosSucursal(valor,1,sucursalUsuario,numPorPagina);
     } 
     function handlePageChange(page) {
        setCurrPage(page);
        //console.log("controler normal",page);
        getProductosSucursal(busqueda,page,sucursalUsuario,numPorPagina);
        // ... do something with `page`
      }
     var editarPS = (editPS) => {
        //console.log(editPS);
        setidProductoSucursal(editPS.id_producto_sucursal);
        setPrecio(0);
        setCantidad(editPS.existencia_producto_sucursal);
        setTitulomodal("Editar Producto "+editPS.clave_producto+" de la sucursal "+editPS.nombre_sucursal+" con clave "+editPS.clave_sucursal);
        setCambiarTipo("Nuevo Precio");
        setEncabezadoModal("Editar Precio");
        setBotonModal("Editar");
        setMotivo("");
        setAccion(1);
        handleShow();
    }
    var aumentarPS = (editPS) => {
        //console.log(editPS);
        setidProductoSucursal(editPS.id_producto_sucursal);
        setPrecio(0);
        setCantidad(editPS.existencia_producto_sucursal);
        setTitulomodal("Editar Producto "+editPS.clave_producto+" de la sucursal "+editPS.nombre_sucursal+" con clave "+editPS.clave_sucursal);
        setCambiarTipo("Aumentar Cantidad");
        setEncabezadoModal("Editar Cantidad");
        setBotonModal("Editar");
        setAccion(2);
        setMotivo("");
        handleShow();
    }
    var disminuirPS = (editPS) => {
        //console.log(editPS);
        setidProductoSucursal(editPS.id_producto_sucursal);
        setPrecio(0);
        setCantidad(editPS.existencia_producto_sucursal);
        setTitulomodal("Editar Producto "+editPS.clave_producto+" de la sucursal "+editPS.nombre_sucursal+" con clave "+editPS.clave_sucursal);
        setCambiarTipo("Disminuir Cantidad");
        setEncabezadoModal("Editar Cantidad");
        setBotonModal("Editar");
        setMotivo("");
        setAccion(3);
        handleShow();
    }
    var activarPS = (activPS) => {
       // console.log(activPS);
        setidProductoSucursal(activPS.id_producto_sucursal);
        if (activPS.estado_producto_sucursal == 1) {
            setaActivoPS(0);
            setMensajeModal("Esta seguro que desea dar de baja el producto "+activPS.clave_producto+" de la sucursal "+activPS.nombre_sucursal+" con clave "+activPS.clave_sucursal);
            setBotonModal("Baja");
            setTitulomodal("Desactivar Producto")
          }else{
            setaActivoPS(1);
            setMensajeModal("Esta seguro que desea dar de alta el producto "+activPS.clave_producto+" de la sucursal "+activPS.nombre_sucursal+" con clave "+activPS.clave_sucursal);
            setBotonModal("Alta");
            setTitulomodal("Activar Producto")
          }
          abrirModal();
    }
    var activarDesactivarPS = async()=>{
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idProductoSucursal":idProductoSucursal,
            "token" : TOKEN,
            "estado_producto_sucursal" : activoPS,
          }
        //console.log('activarDesactivarUsuario',formdata);
         const resp = await activarDesactivarPSApi(formdata);
          if(resp.status === "succes"){
            toast.success(resp.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                setShowModal(false);
                getProductosSucursal(busqueda,1,sucursalUsuario,numPorPagina)
        }else{
            toast.error(resp.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                }); 
            setIsLoading(false);
        }
    }
    var editarProductoSucursal = async() => {
        setIsLoading(true);
        var errores = "";
        var error = 0;
        if(idProductoSucursal.toString() === ""){
            errores+="Algo ocurrio recarga la pagina\n";
            error=1;
        }
        if (motivo.toString() === "") {
            errores+="Debe ingresar el motivo por el cual esta modificando\n";
            error=1; 
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "precio_producto_sucursal": precio,
                "accion": accion,
                "idProductoSucursal": idProductoSucursal,
                "motivo":motivo,
                "token" : TOKEN,
              }
             // console.log(formdata);
             const response = await updateProductoSucursalApi(formdata);
             if(response.status){
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    handleClose();
                    getProductosSucursal(busqueda,1,sucursalUsuario,numPorPagina)
            }else{
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    }); 
                setIsLoading(false);
            }
        }else{
            toast.error(errores, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
             });
             setIsLoading(false); 
        }
    }
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
    
      if(user === null){
        navigate("/login", { replace: true });
      }else{
        if (user.rol!=1 && user.rol!=4 && user.rol != 2) {
            navigate("/", { replace: true });
        } else {
            //console.log(user.usuario_sucursal);
            setIsLoading(true);
            setAuth(user);
            setSucursalUsuario(user.usuario_sucursal);
            getProductosSucursal('',1,user.usuario_sucursal,numPorPagina);
        }
        setAuth(user);
      }
    }
    init();
  }, [getProductosSucursal])
  var setPorPagina = (valor) =>{
    console.log(valor);
    setNumPorPagina(valor);
    getProductosSucursal(busqueda,1,sucursalUsuario,valor)
 }
  return(
    <>
    <div className="contenido">
        <div className="sub_contenido">
            <div className="filtros_pag">
                <Form.Group>
                    <InputGroup>
                        <Form.Label>Mostrar</Form.Label>&nbsp;
                        <Form.Control onChange={event => setPorPagina(event.target.value)} as="select">
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="70">70</option>
                            <option value="100">100</option>
                        </Form.Control>&nbsp;
                        <Form.Label>registros por página</Form.Label>&nbsp;
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="Busqueda"   
                        id="busquedaInput"
                        name="busquedaInput"
                        type="text"
                        onChange={event => setBusquedaFuncion(event.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                <ExcelFile element={<Button>Descargar Inventario</Button>}>
                    <ExcelSheet data={dataReal} name="InventarioSucursal">
                        <ExcelColumn label="Clave Sucursal" value="clave_sucursal"/>
                        <ExcelColumn label="Nombre Sucursal" value="nombre_sucursal"/>
                        <ExcelColumn label="Clave Producto" value="clave_producto"/>
                        <ExcelColumn label="Descripcion Producto" value="descripcion_producto"/>
                        <ExcelColumn label="Precio" value="precio_producto_sucursal"/>
                        <ExcelColumn label="Cantidad" value="existencia_producto_sucursal"/>
                        
                    </ExcelSheet>
                </ExcelFile>
                </Form.Group>
            </div>
            {
                isLoading ? (
                <LoadingSpinner
                showSpinner={isLoading}
                />
                ):
                (
                <>
                </>
                )
            }
            <Table>
                <Thead>
                    <Tr>
                        <Th className="borderTabla">Clave Sucursal</Th>
                        <Th className="borderTabla">Nombre Sucursal</Th>
                        <Th className="borderTabla">Clave Producto</Th>
                        <Th className="borderTabla">Descripcion Producto</Th>
                        <Th className="borderTabla">Precio</Th>
                        <Th className="borderTabla">Cantidad</Th>
                        {
                            auth != null ?(
                                auth.rol == 1 || auth.rol == 4?(
                                    <Th className="borderTabla">Configuración</Th>
                                ):(
                                    <></>
                                )
                            ):(
                                <></>
                            )   
                        } 
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        productoSucursal != null &&
                        (
                            map(productoSucursal,(ps,index) =>(
                                <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                    <Td className="borderTabla">{ps.clave_sucursal}</Td>
                                    <Td className="borderTabla">{ps.nombre_sucursal}</Td>
                                    <Td className="borderTabla">{ps.clave_producto}</Td>
                                    <Td className="borderTabla">{ps.descripcion_producto}</Td>
                                    <Td className="borderTabla">{ps.precio_producto_sucursal}</Td>
                                    <Td className="borderTabla">{ps.existencia_producto_sucursal}</Td>
                                    {
                                        auth != null ?(
                                            auth.rol == 1 || auth.rol == 4?(
                                                <Td className="borderTabla">
                                                <div className="configuracionBotones">
                                                    <Button title="Precio" onClick={() => editarPS(ps)}  className='precioBoton transparente'></Button>
                                                    <Button title="Aumentar Cantidad" onClick={() => aumentarPS(ps)}  className='masBoton transparente'></Button>
                                                    {
                                                      auth.rol == 1?(
                                                        <Button title="Disminuir Cantidad" onClick={() => disminuirPS(ps)}  className='menosBoton transparente'></Button>
                                                      ):(
                                                        <></>
                                                      ) 
                                                    }
                                                    <a title="Otras Sucursales" href={`/productos/${ps.id_producto}`} className="botonView transparente"></a>  
                                                    <Button onClick={() => activarPS(ps)} title={ ps.estado_producto_sucursal != 1 ? ('Activar'):('Desactivar') } className={  ps.estado_producto_sucursal != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                                    <a title="Historial Cantidad" href={`/historialProductos/${ps.id_producto_sucursal}`} className="preciosBoton"></a>  
                                                 </div>
                                                 </Td>
                                            ):(
                                                <></>
                                            )
                                        ):(
                                            <></>
                                        )
                                    }
                                </Tr>
                            ))
                        )
                    }
                </Tbody>
            </Table>
            &nbsp;						
            <div className="alignDerecha">
                {
                    productoSucursal != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                ):
                (
                    <p>No hay registros</p>
                )
                }
            </div>
            <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
        </div>
    </div> 
    <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{encabezadoModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                      <Row className='centrarTexto'>
                            <Col md={12}>
                                <Form.Group>
                                    <h6>{tituloModal}</h6>                                    
                                </Form.Group>
                            </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>Motivo de la modificación</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={7}>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} 
                                    defaultValue={motivo}
                                    onChange={event => setMotivo(event.target.value)}
                                />
                            </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={5}>
                            <Form.Group>
                                <Form.Label>{cambiarTipo}</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={7}>
                        <Form.Group>
                            <Form.Control placeholder="nombre"   
                             id="idProductoSucursal"
                             name="idProductoSucursal"
                             type="hidden"
                             defaultValue={idProductoSucursal}
                             />
                            <Form.Control placeholder="nombre"   
                             id="accion"
                             name="accion"
                             type="hidden"
                             defaultValue={accion}
                             />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control placeholder="Precio"   
                             id="modificar"
                             name="modificar"
                             type="number"
                             min="0"
                             defaultValue={precio}
                             onChange={event => setPrecio(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>                     
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={editarProductoSucursal} id="botonModal">
                        {botonModal}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
        </Modal>
        <Modal  show={showModal} onHide={cerrarModal} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="idUsuarioActivar"
                        name="idProductoSucursal"
                        type="hidden"
                        defaultValue={idProductoSucursal}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="activoPS"
                        name="activoPS"
                        type="hidden"
                        defaultValue={activoPS}
                    />
                </Form.Group>
                <h6>{mensajemodal}</h6>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={activarDesactivarPS} id="botonModal">
                {botonModal}
            </Button>
            <Button variant="secondary" onClick={cerrarModal}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>      
    </>
  )
}

export default SucursalGerente;
