import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import Select from 'react-select';
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import logoUsuario  from "../../assets/logos/usuario.png"
import { getDatosUsers } from '../../api/Login';
import { useNavigate } from "react-router-dom";
import { getRolesApi,getUsuariosApi,saveUsuarioApi,activarUsuarioApi, resetUserApi } from '../../api/Usuarios';
import { getSelectSucursalesApi } from '../../api/Sucursal';
import { getTokenApi } from '../../api/Token';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import LoadingSpinner from '../views/LoadingSpinner';
const diasOptions = [
    { value: 'Lunes', label: 'Lunes' },
    { value: 'Martes', label: 'Martes' },
    { value: 'Miercoles', label: 'Miercoles' },
    { value: 'Jueves', label: 'Jueves' }, 
    { value: 'Viernes', label: 'Viernes' }, 
    { value: 'Sabado', label: 'Sabado' },
    { value: 'Domingo', label: 'Domingo' }
  ];

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      
      color: state.isSelected ? 'yellow' : 'black',
      backgroundColor: state.isSelected ? 'green' : 'white',
    }),
    control: (provided) => ({
      ...provided,
     // marginTop: "5%",
    })
  }

function Usuarios(){
    const [isLoading, setIsLoading] = useState(false);
    const [auth, setAuth] = useState(null);
    let navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalResetear, setShowModalResetear] = useState(false);
    const [usuarios, setUsuarios] = useState(null);
    /*inputs selects*/
    const [rol, setRoles] = useState(null);
    const [sucursal,setSucursales] = useState(null);
    const [valorSelectRol,setValorSelectRol] = useState(null);
    const [valorSelectScursal,setValorSelectScursal] = useState(null);
    const [defaultSelectRol,setDefaultValorSelectRol] = useState(null);
    const [defaultSelectScursal,setDefaultValorSelectScursal] = useState(null);
    //inputs
    const [dias,setDiasValor] = useState(null);
    const [idUsuario,setIdUsuarioValor] = useState(0);
    const [nombre,setNombreValor] = useState("");
    const [nomUser,setNomUserValor] = useState("");
    const [horaEntrada,setHoraEntrada] = useState("");
    const [horaSalida,setHoraSalida] = useState("");
    const [password,setPassword] = useState("");
    const [passwordRepeat,setPasswordRepeat] = useState("");
    const[activoUsuario,setActivoUsuario] = useState(0);
    const [resetUsuario,setResetUsuario] = useState(0);
    const [tituloModal,setTitulomodal] = useState("");
    const [botonModal,setBotonModal] = useState("");
    const [mensajemodal,setMensajeModal] = useState("");
    /*modals*/
    const [busqueda,setBusqueda] = useState("");

    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 

    /*obtener a los usuarios*/
    const getUsuarios = useCallback( async(buscar,page_number) => {
        //console.log(buscar);
        setBusqueda(buscar);
        const TOKEN = await getTokenApi();
        const formdata = {
            "busqueda": buscar,
            "token" : TOKEN,
            "pagina" : page_number,
          }
        setCurrPage(page_number);
        var usariosR = await getUsuariosApi(formdata);
        //console.log(usuario.totalPaginas);
        setTotalPaginas(usariosR.totalPaginas); 
        setUsuarios(usariosR.usuarios); 
        setIsLoading(false); 
    },[]);

     useEffect(() => {
        setIsLoading(true);
        getUsuarios('',1);
    }, [getUsuarios])
    /**modals */
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true); 

     const cerrarModal = () => setShowModal(false);
     const abrirModal = () => setShowModal(true);

     const cerrarModalReset = () => setShowModalResetear(false);
     const abrirModalReset = () => setShowModalResetear(true);
     useEffect(()=>{
        (async()=>{
            const TOKEN = await getTokenApi();
            const formdata = {
                "token" : TOKEN,
            }
            const roles = await getRolesApi(formdata);
            var option = [];
            if(roles.error == null){
                roles.roles.map((role,index)=>(
                    option.push({
                        value:role.id_rol,
                        label:role.desc_rol
                    })             
                ))
                setRoles(option);
            }
            option = [];
            const sucursal = await getSelectSucursalesApi(formdata);
            if(sucursal.error == null){
                sucursal.sucursales.map((suc,index)=>(
                    option.push({
                        value:suc.id_sucursal,
                        label:suc.clave_sucursal+" "+suc.nombre_sucursal
                    })
                ));
                setSucursales(option);
            }
            
          })();
    },[]);
    /* select multiple opciones */
    var multipleSelect = (e) =>{
        setValorSelectRol(e.value);
        //console.log(e);
    }
    /* select multiple opciones */
    var multipleSelectSucursal = (e) =>{
        setValorSelectScursal(e.value);
        //console.log(e);
    }
    var multipleSelectDias = (e) =>{
        //setDiasValor(Array.isArray(e)?e.map(x=>x.value):[]);
        setDiasValor(e);
    }

    var usuarioNuevo = () => {
        setIdUsuarioValor(0);
        setNombreValor("");
        setNomUserValor("");
        setDiasValor(null);
        setHoraEntrada("");
        setHoraSalida("");
        setDefaultValorSelectRol(null);
        setDefaultValorSelectScursal(null);
        setPassword("");
        setPasswordRepeat("");
        setTitulomodal("Añadir");
        setBotonModal("Agregar");
        handleShow();
     }
     var editarUsuario = (userEditar) =>{
        setValorSelectRol(null);
        setValorSelectScursal(null);
        setIdUsuarioValor(userEditar.id_usuario);
        setNombreValor(userEditar.nombre_usuario);
        setNomUserValor(userEditar.user_usuario);
        setDiasValor(JSON.parse(userEditar.dias_trabajo_usuario));
        setHoraEntrada(userEditar.hora_trabajo_entrada_usuario);
        setHoraSalida(userEditar.hora_trabajo_salida_usuario);
        map(rol,(r) =>{
            if (r.value == userEditar.rol_usuario) {
                setDefaultValorSelectRol(r);
                multipleSelect(r);
            }
        });
        map(sucursal,(suc) =>{
            if (suc.value == userEditar.id_sucursal_foranea) {
                setDefaultValorSelectScursal(suc);
                multipleSelectSucursal(suc)
            }
        });
        setPassword("");
        setPasswordRepeat("");
        setTitulomodal("Editar");
        setBotonModal("Editar");
         handleShow();
     }
     var activarUsuario = (userActivar) =>{
        setIdUsuarioValor(userActivar.id_usuario);
        if (userActivar.estado_usuario == 1) {
          setActivoUsuario(0);
          setTitulomodal("Desactivar");
          setBotonModal("Desactivar");
          setMensajeModal("¿Esta seguro que desea desactivar el usuario "+userActivar.nombre_usuario+" ?");
        }else{
          setActivoUsuario(1);
          setTitulomodal("Activar");
          setBotonModal("Activar");
          setMensajeModal("¿Esta seguro que desea activar el usuario "+userActivar.nombre_usuario+" ?");
        }
        abrirModal();
    }
     var crearUsuario = async() =>{
        setIsLoading(true);
        const validaPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
        var errores = "";
        var error = 0;
        if(idUsuario.toString() == ""){
            errores+="Algo ocurrio recarga la pagina\n";
            error=1;
        }else{
            if (idUsuario.toString() === "0") {
                /*Validar contraseñas*/
                if(password.toString() == "" || (!validaPassword.test(password.toString()))){
                    errores+="La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula NO puede tener otros símbolos.\n";
                    error=1;
                }
                if(password.toString() != passwordRepeat.toString()){
                    errores+="Las constraseñas no coinciden\n";
                    error=1;
                }
            }else{
                /*preguntar al usuario si desea cambiar las contraseña de ser asi se verifica que las contraseñas
                coincidan de lo contrario se ponen en blanco las contraseñas*/
                if(password.toString() !== "" || passwordRepeat.toString() !== ""){
                   alert('Si quiere cambiar las contraseñas ambas deben coincidir de lo contrario deje los campos en blanco');
                   if(password.toString() == "" || (!validaPassword.test(password.toString()))){
                    errores+="La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula NO puede tener otros símbolos.\n";
                    error=1;
                    }
                    if(password.toString() != passwordRepeat.toString()){
                        errores+="Las constraseñas no coinciden\n";
                        error=1;
                    }
                }
            }
        }

       
        if(nombre.toString() == ""){
            errores+="Debes ingresar el nombre del trabajador\n";
            error=1;
        }
        if(nomUser.toString() == ""){
            errores+="Debes ingresar el nombre de usuario del trabajador con el que podra acceder a la plataforma\n";
            error=1;
        }
        if(Array.isArray(dias) && dias.length== 0){
            errores+="Debes de seleccionar al menos un dia en el que labora\n";
            error=1;
        }else{
            if(dias == null){
                errores+="Debes de seleccionar los días que labora\n";
                error=1;
            }
        }
        if(horaEntrada.toString() == ""){
            errores+="Debes ingresar la hora de entrada\n";
            error=1;
        }
        if(horaSalida.toString() == ""){
            errores+="Debes ingresar la hora de salida\n";
            error=1;
        }
        if(Array.isArray(valorSelectRol) && valorSelectRol.length== 0){
            errores+="Debes de seleccionar el rol del trabajador\n";
            error=1;
        }else{
            if(valorSelectRol == null){
                errores+="Debes de seleccionar el rol del trabajador\n";
                error=1;
            }
        }
        if(Array.isArray(valorSelectScursal) && valorSelectScursal.length== 0){
            errores+="Debes de seleccionar en que sucursal labora\n";
            error=1;
        }else{
            if(valorSelectScursal == null){
                errores+="Debes de seleccionar en que sucursal labora\n";
                error=1;
            }
        }
        if(error != 1){
            const TOKEN = await getTokenApi();
            const formdata = {
                "user_usuario": nomUser,
                "nombre_usuario": nombre,
                "password_usuario": password,
                "passwordRepeat": passwordRepeat,
                "dias_trabajo_usuario":dias,
                "hora_trabajo_entrada_usuario":horaEntrada,
                "hora_trabajo_salida_usuario":horaSalida,
                "rol_usuario": valorSelectRol,
                "id_sucursal_foranea":valorSelectScursal,
                "idUsuario":idUsuario,
                "token" : TOKEN,
              }
             const response = await saveUsuarioApi(formdata);
             if(response.status){
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    });
                    handleClose();
                    getUsuarios(busqueda,1)
                    //getSucursales(busqueda,1);
            }else{
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark"
                    }); 
                setIsLoading(false);
            }
        }else{
            toast.error(errores, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
             }); 
             setIsLoading(false);
        }
     }
     var activarDesactivarUsuario  = async() =>{
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idUsuario":idUsuario,
            "token" : TOKEN,
            "estado_usuario" : activoUsuario,
          }
        // console.log('activarDesactivarUsuario',formdata);
         const resp = await activarUsuarioApi(formdata);
          if(resp.status === "succes"){
            toast.success(resp.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                setShowModal(false);
                getUsuarios(busqueda,1);
        }else{
            toast.error(resp.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                }); 
            setIsLoading(false);
        }
     }
     var setBusquedaFuncion = (valor) =>{
        setIsLoading(true);
        getUsuarios(valor,1);
     }
     function handlePageChange(page) {
        setIsLoading(true);
        setCurrPage(page);
        //console.log("controler normal",page);
        getUsuarios(busqueda,page);
        // ... do something with `page`
      }
    /*Verificar si no ahy un usuario logueado*/
    useEffect(() => {
        const init = async() =>{
        const user = await getDatosUsers();
        if(user === null){
            navigate("/login", { replace: true });
        }else{
            if (user.rol!=1) {
                navigate("/", { replace: true });
            } else {
                setAuth(user);
            }
        }
        }
        init();
    }, [])
    var resetearUsuario = async(userActivar)=> {
        //console.log(userActivar);
        setIdUsuarioValor(userActivar.id_usuario);
        if (userActivar.is_login == 1) {
            setActivoUsuario(0);
            setTitulomodal("Activar Sesion de usuario");
            setBotonModal("Activar Sesion");
            setMensajeModal("¿Esta seguro que desea activar la sesion del usuario "+userActivar.nombre_usuario+" ?");
          }else{
            setActivoUsuario(1);
            setTitulomodal("Activar");
            setBotonModal("Activar");
            setMensajeModal("¿Esta seguro que desea desactivar la sesion del usuario "+userActivar.nombre_usuario+" ?");
          }
          abrirModalReset();
    }
    var resetUser = async() => {
        setIsLoading(true);
        const TOKEN = await getTokenApi();
        const formdata = {
            "idUsuario":idUsuario,
            "token" : TOKEN,
            "estado_usuario" : activoUsuario,
          }
        // console.log('activarDesactivarUsuario',formdata);
         const resp = await resetUserApi(formdata);
          if(resp.status === "succes"){
            toast.success(resp.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                });
                setShowModalResetear(false);
                getUsuarios(busqueda,1);
        }else{
            toast.error(resp.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark"
                }); 
            setIsLoading(false);
        }
    }
    return (
        <>
        <div className="contenido">
            <div className="sub_contenido">
                <div className="filtros_pag">
                    <Form.Group>
                        <InputGroup>
                            <Form.Control placeholder="Busqueda"   
                                id="busquedaInput"
                                name="busquedaInput"
                                type="text"
                                onChange={event => setBusquedaFuncion(event.target.value)}

                            />
                            &nbsp;
                            <Button title="Nuevo Usuario" onClick={usuarioNuevo} className="botonIcono botonNuevo showmessages">Nuevo Usuario</Button>
                        </InputGroup>
                        
                    </Form.Group>
                    
                </div>
                {
                    isLoading ? (
                        <LoadingSpinner
                        showSpinner={isLoading}
                        />
                    ):
                    (
                    <>
                    </>
                    )
                }
                <Table>
                    <Thead>
                        <Tr>
                            <Th className="borderTabla"></Th>
                            <Th className="borderTabla">Nombre de Usuario</Th>
                            <Th className="borderTabla">Nombre</Th>
                            <Th className="borderTabla">Días</Th>
                            <Th className="borderTabla">Horario</Th>
                            <Th className="borderTabla">Rol</Th>
                            <Th className="borderTabla">Sucursal</Th>
                            <Th className="borderTabla">Configuración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                {
                    usuarios != null &&
                    (
                        map(usuarios,(user,index)=>(
                            <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                <Td className="borderTabla"><img src={logoUsuario} className="imgUsuarios" alt=""/></Td>
                                <Td className="borderTabla">{user.user_usuario}</Td>
                                <Td className="borderTabla">{user.nombre_usuario}</Td>
                                <Td className="borderTabla">
                                 {
                                    user.dias_trabajo_usuario != null &&
                                    map(JSON.parse(user.dias_trabajo_usuario),(diasTrabajo)=>(
                                      <label key={diasTrabajo.value}>{diasTrabajo.label} &nbsp;</label>
                                    ))
                                 }
                                </Td>
                                <Td className="borderTabla">{user.hora_trabajo_entrada_usuario} a {user.hora_trabajo_salida_usuario}</Td>
                                <Td className="borderTabla">{user.desc_rol}</Td>
                                <Td className="borderTabla">{user.clave_sucursal} {user.nombre_sucursal}</Td>
                                <Td className="borderTabla">
                                    <div className="configuracionBotones">
                                      <Button title="Editar Usuario" onClick={() => editarUsuario(user)}  className='botonEditar transparente'></Button>
                                      <Button onClick={() => activarUsuario(user)} title={ user.estado_usuario != 1 ? ('Activar'):('Desactivar') } className={ user.estado_usuario != 1 ? ('botonDelete transparente'):('botonActive transparente') } type='button' name='button'></Button>
                                      <Button onClick={() => resetearUsuario(user)} title={ user.is_login != 0 ? ('Activar Sesion'):('Desactivar Sesion') } className={ user.is_login != 0 ? ('botonBlock transparente'):('botonActivelog transparente') } type='button' name='button'></Button>
                                      <a title="Folios Entregados" href={`/ticketsUsuarios/${user.id_usuario}`} className="ticketBoton"></a>  
                                    </div>
                                </Td>
                            </Tr>
                        ))
                    )
                }
                    </Tbody>
                </Table>
                &nbsp;						
                <div className="alignDerecha">
                {
                    usuarios != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                    ):
                    (
                    <p>No hay registros</p>
                    )
                }
                </div>
                <ToastContainer
                            transition={Flip}
                            position="bottom-center"
                            limit={1}
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
            </div>
        </div>
        <Modal  show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Nombre</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="nombre"   
                             id="idUsuario"
                             name="idUsuario"
                             type="hidden"
                             defaultValue={idUsuario}
                             />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control placeholder="Nombre del Trabajador"   
                             id="nombre"
                             name="nombre"
                             type="text"
                             defaultValue={nombre}
                             onChange={event => setNombreValor(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Nombre de Usuario</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Nombre de Usario"   
                             id="nomUser"
                             name="nomUser"
                             type="text"
                             defaultValue={nomUser}
                             onChange={event => setNomUserValor(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                    
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Días</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                        <Select
                            placeholder='Selecciona los días'
                            options={diasOptions}
                            styles={customStyles}
                            isMulti
                            defaultValue={dias}
                            id="dias"
                            onChange={multipleSelectDias} />

                        </Form.Group>
                        </Col>
                      </Row>
                     
                      <Row>
                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>Horario de Trabajo</Form.Label>
                            </Form.Group>
                        </Col>
                      </Row>
                    
                      <Row>
                        <Col md={6}>
                        <Form.Group>
                            <Form.Control 
                                type="time" 
                                name="horaEntrada"
                                defaultValue={horaEntrada} 
                                placeholder="Entrada"
                                onChange={event => setHoraEntrada(event.target.value)}
                                />                                
                        </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Group>
                            <Form.Control 
                                type="time" 
                                name="horaSalida" 
                                defaultValue={horaSalida} 
                                placeholder="Salida"
                                onChange={event => setHoraSalida(event.target.value)}
                                />
                        </Form.Group>
                        </Col>
                      </Row>
                    
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Rol</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Select
                               placeholder='Rol'
                               options={rol}
                               styles={customStyles}
                               id="rolSelect"
                               defaultValue={defaultSelectRol}
                               onChange={multipleSelect} />

                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Sucursal</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Select
                               placeholder='Sucursal'
                               options={sucursal}
                               styles={customStyles}
                               id="sucursalSelect"
                               defaultValue={defaultSelectScursal}
                               onChange={multipleSelectSucursal} />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Contraseña</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Contraseña"   
                             id="password"
                             name="password"
                             type="password"
                             defaultValue={password}
                             onChange={event => setPassword(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Repetir Contraseña</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col md={9}>
                        <Form.Group>
                            <Form.Control placeholder="Repetir Contraseña"   
                             id="passwordRepeat"
                             name="passwordRepeat"
                             type="password"
                             defaultValue={passwordRepeat}
                             onChange={event => setPasswordRepeat(event.target.value)}
                             />
                        </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={crearUsuario} id="botonModal">
                        {botonModal}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
        </Modal>

        <Modal  show={showModal} onHide={cerrarModal} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="idUsuarioActivar"
                        name="idUsuario"
                        type="hidden"
                        defaultValue={idUsuario}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="resetUsuario"
                        name="resetUsuario"
                        type="hidden"
                        defaultValue={resetUsuario}
                    />
                </Form.Group>
                <h4>{mensajemodal}</h4>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={activarDesactivarUsuario} id="botonModal">
                {botonModal}
            </Button>
            <Button variant="secondary" onClick={cerrarModal}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
        <Modal  show={showModalResetear} onHide={cerrarModalReset} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title id="tituloModal">{tituloModal} Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="idUsuarioActivar"
                        name="idUsuario"
                        type="hidden"
                        defaultValue={idUsuario}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control placeholder="nombre"   
                        id="activoUsuario"
                        name="activoUsuario"
                        type="hidden"
                        defaultValue={activoUsuario}
                    />
                </Form.Group>
                <h4>{mensajemodal}</h4>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={resetUser} id="botonModal">
                {botonModal}
            </Button>
            <Button variant="secondary" onClick={cerrarModalReset}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>
        </>
 
    )
}
export default Usuarios