import React,{useEffect, useState} from 'react';
import { Outlet,useNavigate} from 'react-router-dom';
import { Nav, Navbar , NavDropdown} from 'react-bootstrap';
import { getTokenApi, removeTokenApi } from '../../api/Token';
import { getDatosUsers } from '../../api/Login';
import { removeSesionApi } from '../../api/Usuarios';
function Layout(props) {
    const [auth, setAuth] = useState(null);
    let navigate = useNavigate();
    const Logout = async() => {
        const TOKEN = await getTokenApi();
        const FormData ={
            "token" : TOKEN
        }
        const resp = await removeSesionApi(FormData);
        if (resp.status) {
            removeTokenApi();
            window.location.reload();
            navigate('/login');
        }
       
      }
    /*Verificar si no ahy un usuario logueado*/
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
        const user = await getDatosUsers();
        //console.log('layaout',user);
        if(user === null){
           setAuth(null)
        }else{
            setAuth(user);
        }
    }
    init();
    }, [])

    return (
    <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/login">&nbsp;&nbsp;MULSO</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  {
                    auth?(
                       auth.rol == 1?(
                        <>
                         <Nav.Link href="/sucursales">Sucursales</Nav.Link>
                         <Nav.Link href="/users">Usuarios</Nav.Link>
                         <Nav.Link href="/productos">Productos</Nav.Link>
                         <Nav.Link href="/ventas">Ventas</Nav.Link>
                         <Nav.Link href="/leerQR">EscanearQR</Nav.Link>
                         <Nav.Link href="/ticketsEntregados">QREntregados</Nav.Link>
                        
                         <Nav.Link onClick={Logout}>Salir</Nav.Link>
                        </>
                       ):(
                        auth.rol == 4?(
                            <>
                            <NavDropdown title="Productos" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/sucursalGerente">General</NavDropdown.Item>
                                <NavDropdown.Item href="/existenciaGerente">En Existencia</NavDropdown.Item>
                                {/*
                                    <Nav.Link href="/sucursalGerente">General</Nav.Link>
                                    <Nav.Link href="/sucursalGerente">En Existencia</Nav.Link>
                                */}
                            </NavDropdown>
                            <Nav.Link href="/historialMovimientos">Movimientos</Nav.Link>
                            <Nav.Link href="/ventas">Vender</Nav.Link>
                            <Nav.Link href="/registroventas">Folios</Nav.Link>
                            <Nav.Link href="/usersSucursal">Usuarios</Nav.Link>
                            <Nav.Link href="/leerQR">EscanearQR</Nav.Link>
                            <Nav.Link href="/ticketsEntregados">QREntregados</Nav.Link>

                            <Nav.Link onClick={Logout}>Salir</Nav.Link>
                            </>
                        ):(
                            auth.rol == 3?(
                                <>
                                <Nav.Link href="/leerQR">EscanearQR</Nav.Link>
                                <Nav.Link href="/ticketsEntregados">QREntregados</Nav.Link>
                                <Nav.Link onClick={Logout}>Salir</Nav.Link>
                                </>
                            ):(
                                auth.rol == 5 ? (
                                    <>
                                    <Nav.Link href="/ventas">Vender</Nav.Link>
                                    <Nav.Link onClick={Logout}>Salir</Nav.Link>
                                    </>
                                ):(
                                    <>
                                    <NavDropdown title="Productos" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/sucursalGerente">General</NavDropdown.Item>
                                        <NavDropdown.Item href="/existenciaGerente">En Existencia</NavDropdown.Item>
                                        {/*
                                            <Nav.Link href="/sucursalGerente">General</Nav.Link>
                                            <Nav.Link href="/sucursalGerente">En Existencia</Nav.Link>
                                        */}
                                    </NavDropdown>
                                    <Nav.Link href="/ventas">Vender</Nav.Link>
                                    <Nav.Link href="/registroventas">Folios</Nav.Link>
                                    <Nav.Link href="/leerQR">EscanearQR</Nav.Link>
                                    <Nav.Link href="/ticketsEntregados">QREntregados</Nav.Link>
                                    <Nav.Link onClick={Logout}>Salir</Nav.Link>
                                    </>
                                ) 
                            )   
                          )
                       ) 
                    ):(
                        <></>
                    )
                  }
                    </Nav>

            </Navbar.Collapse>
        </Navbar>
        <div className='usuarioNom'>
            {
                auth != null &&(
                   <p>{auth.nomUsuario}</p>
                )
            }
        </div>
        <div className="content">
            <Outlet />
            
        </div>
    </div>
    )
}

export default Layout;
