import React from 'react';
function Header() {
    return (
          <div className="head">
            <div className="img">
              <div className="flex1">
              
              </div>
              &nbsp;
              <div className="flex2">
                <h2 className="nom_sistema">MULTI MARCAS SOTO</h2>
              </div>
            </div>
          </div>
      );
}

export default Header;
