import { TOKEN } from '../utils/constant';
import { AsyncStorage } from 'AsyncStorage';

export async function setTokenApi(token){
    try {
        await AsyncStorage.setItem(TOKEN, token);
        return true;
    } catch (error) {
        return null;
    }

}

export async function getTokenApi(){
  try {
      const token = await AsyncStorage.getItem(TOKEN);
      if(typeof token === 'undefined'){
        return "";
      }else{
        return token;
      }
     
      
  } catch (error) {
     return null; 
  }
}

export async function removeTokenApi(){
    try {
       // console.log("este el es token para remove", TOKEN );
        const token = await localStorage.removeItem('@AsyncStorage:'+TOKEN);
        //const token = await AsyncStorage.removeItem('@AsyncStorage:'+TOKEN);
       // console.log(token);
    return true;
    } catch (error) {
        return null;
    }
}