import React, {useState,useEffect,useCallback} from 'react';
import { Button,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import { ToastContainer, toast, Flip } from 'react-toastify';
import Paginacion from '../paginacion/Paginacion';
import {map} from 'lodash';
import { getDatosUsers } from '../../api/Login';
import {
    Navigate,useNavigate,useParams
  } from "react-router-dom";
import { cancelarFolioByIdApi, getVentaBySucursalByIdApi } from '../../api/Ventas';
import { getTokenApi } from '../../api/Token';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';

function RegistroVentas() {
    let params = useParams();
   const [auth, setAuth] = useState(null);
   const [sucursalId,setSucursalId] = useState(null);
   const [busqueda,setBusqueda] = useState("");
   const [folios,setFolios] = useState(null);
   const [folioCancelar,setFolioCancelar] = useState(0);
    /*cosas para usar la pagiancion*/
   const [tagList, setTagList] = useState([]);
   const [currPage, setCurrPage] = useState(1);
   const [totalPaginas,setTotalPaginas] = useState(0);
   const [mensajemodal,setMensajeModal] = useState("");

   const [showModal, setShowModal] = useState(false);
   const cerrarModal = () => setShowModal(false);
   const abrirModal = () => setShowModal(true);
   const [eFolio,setEFolio] = useState(10);
   let navigate = useNavigate();

   const [fechaini,setFechaini] = useState("");
   const [fechafin,setFechafin] = useState("");
   /*Obtener Sucursales*/
   const getFolios = useCallback( async(buscar,page_number,sucursal,estadoFolio,fechainib,fechafinb) => {
    //console.log(buscar);
    setBusqueda(buscar);
    const TOKEN = await getTokenApi();
    //console.log(TOKEN);
    const formdata = {
        "busqueda": buscar,
        "token" : TOKEN,
        "pagina" : page_number,
        "id_sucursal" :sucursal,
        "band":0,
        "estadoFolio":estadoFolio,
        "fechaini":fechainib,
        "fechafin":fechafinb,
      }
    setCurrPage(page_number);
    var folio = await getVentaBySucursalByIdApi(formdata);
    if(!folio.error){
         //console.log(usuario.totalPaginas);
        setTotalPaginas(folio.totalPaginas); 
        setFolios(folio.folios);  
    }
   
},[]);
    /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
     //console.log(user);
     if(user === null || user.rol == 5){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
        if (params.sucursalId != null) {
            setSucursalId(params.sucursalId);
            getFolios('',1,params.sucursalId,eFolio,fechaini,fechafin);  
        } else {
            setSucursalId(user.usuario_sucursal);
            getFolios('',1,user.usuario_sucursal,eFolio,fechaini,fechafin);
        }
       
      }
    }
    init();
  }, [])
  var setBusquedaFuncion = (busqueda) =>{
    //console.log(busqueda);
    getFolios(busqueda,1,sucursalId,eFolio,fechaini,fechafin);
  }
  function handlePageChange(page) {
    setCurrPage(page);
    //console.log("controler normal",page);
    getFolios(busqueda,page,sucursalId,eFolio,fechaini,fechafin);
    // ... do something with `page`
  }
  var verCorte = () =>{
    /**redirigir al corte del dia */
    navigate(`/corte/${sucursalId}`, { replace: true });
  }
  var cancelarFolio = async() => {
    const TOKEN = await getTokenApi();
    const formdata = {
        "token" : TOKEN,
        "folio" :folioCancelar,
      }
    //console.log(formdata);
    const resp = await cancelarFolioByIdApi(formdata);
             if(resp.status === "succes"){
               toast.success(resp.message, {
                   position: "top-center",
                   autoClose: 5000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
                   });
                   setShowModal(false);
                   getFolios('',1,sucursalId,eFolio,fechaini,fechafin);
           }else{
               toast.error(resp.message, {
                   position: "top-center",
                   autoClose: 3000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "dark"
                   }); 
           }
  }
  var cancelaFolio = (venta) => {
    setFolioCancelar(venta.id_folio);
    setMensajeModal("¿Esta seguro que desea cancelar el ticket con el folio "+venta.numero_folio+" ?");
    abrirModal();
  }
  var setEstadoFolio = (valor) =>{
    //console.log(valor);
    setEFolio(valor);
    //setNumPorPagina(valor);
    getFolios(busqueda,1,sucursalId,valor,fechaini,fechafin);
 }
 var setFechainiB = (valor) =>{
    setFechaini(valor)
    if(fechafin != ""){
      getFolios(busqueda,1,sucursalId,eFolio,valor,fechafin);
    }else{
      if (fechafin == "" && fechaini == "") {
        getFolios(busqueda,1,sucursalId,eFolio,"","");
      }
    }
 }
 var setFechafinB = (valor) =>{
  setFechafin(valor)
  if(fechaini != ""){
    getFolios(busqueda,1,sucursalId,eFolio,fechaini,valor);
  }else{
    if (fechafin == "" && fechaini == "") {
      getFolios(busqueda,1,sucursalId,eFolio,"","");
    }
  }
 }
 return (
  <>
  <div className="contenido">
        <div className="sub_contenido">
            <div className="filtros_pag">
                <Form.Group>
                  <InputGroup>
                    <Form.Label>Folios</Form.Label>&nbsp;
                    <Form.Control onChange={event => setEstadoFolio(event.target.value)} as="select">
                        <option value="10">Todos</option>
                        <option value="0">En Espera</option>
                        <option value="1">Entregado</option>
                        <option value="2">Cancelado</option>
                    </Form.Control>&nbsp;
                  </InputGroup>
                </Form.Group>  
                <Form.Group>
                  <InputGroup>
                    <Form.Control 
                      type="date" 
                      name="horaEntrada"
                      defaultValue={fechaini} 
                      placeholder="Entrada"
                      onChange={event => setFechainiB(event.target.value)}
                    />&nbsp;  
                    <Form.Control 
                      type="date" 
                      name="horaSalida" 
                      defaultValue={fechafin} 
                      placeholder="Salida"
                      onChange={event => setFechafinB(event.target.value)}
                    />&nbsp;
                    </InputGroup>
                </Form.Group>
                <Form.Group>
                  <InputGroup>
                    <Form.Control placeholder="Busqueda"   
                        id="busquedaInput"
                        name="busquedaInput"
                        type="text"
                        onChange={event => setBusquedaFuncion(event.target.value)}
                    />&nbsp;
                    <Button title="Corte de Caja" onClick={verCorte} >Corte de Caja</Button>
                  </InputGroup>
                </Form.Group>
           </div>
           <Table>
                <Thead>
                    <Tr>
                        <Th className="borderTabla">Folio</Th>
                        <Th className="borderTabla">Cliente</Th>
                        <Th className="borderTabla">Estado</Th>
                        <Th className="borderTabla">No. Articulos</Th>
                        <Th className="borderTabla">Total $$</Th>
                        <Th className="borderTabla">Fecha</Th>
                        <Th className="borderTabla">Configuración</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        folios != null &&
                        (
                          map(folios,(fol,index) =>(
                            <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                                <Td className="borderTabla">{fol.numero_folio}</Td>
                                <Td className="borderTabla">{fol.nombre_cliente_folio}</Td>
                                <Td className="borderTabla">
                                    {
                                        fol.estado_folio == 0 ?(
                                            <p>En Espera</p>
                                        ):(
                                            fol.estado_folio == 1 ?(
                                                <p>Entregado</p>
                                            ):( 
                                                <p>Cancelado</p>
                                            )
                                        )
                                    }                               
                                </Td>
                                <Td className="borderTabla">{fol.articulos_comprados}</Td>
                                <Td className="borderTabla">{fol.total_vendio_folio}</Td>
                                <Td className="borderTabla">{fol.fecha_folio}</Td>
                                <Td className="borderTabla">
                                    <div className="configuracionBotones">
                                        {
                                        auth.rol == 4 &&  fol.estado_folio == 0 ?(
                                            <Button onClick={() => cancelaFolio(fol)} title="Cancelar" className='botonDelete transparente' type='button' name='button'></Button>
                                        ):(
                                            auth.rol == 1 &&  fol.estado_folio != 2 ?(
                                            <Button onClick={() => cancelaFolio(fol)} title="Cancelar" className='botonDelete transparente' type='button' name='button'></Button>
                                            ):(
                                                <></>
                                            )
                                        )
                                        }
                                        <a title="Ticket" target="_blank" href={`/folio/${fol.id_folio}`} className="botonView "></a>
                                    </div>  
                                </Td>
                            </Tr>
                          ))
                        )
                    }
                </Tbody>
            </Table>  
            &nbsp;
            <div className="alignDerecha">
                {
                    folios != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                ):
                (
                    <p>No hay registros</p>
                )
                }
            </div> 
            <ToastContainer
                transition={Flip}
                position="bottom-center"
                limit={1}
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    </div>
    <Modal  show={showModal} onHide={cerrarModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title id="tituloModal">Cancelar Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
            <Form.Group>
                <Form.Control placeholder="nombre"   
                    id="folioCancelar"
                    name="folioCancelar"
                    type="hidden"
                    defaultValue={folioCancelar}
                />
            </Form.Group>
             <h4>{mensajemodal}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={cancelarFolio} id="botonModal">
            Aceptar
          </Button>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
  </>      
 )
}

export default RegistroVentas;

