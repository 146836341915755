import React,{useCallback,useContext, useState,useEffect} from 'react';
//import AuthContext from '../../context/AuthContext';
import adapter from 'webrtc-adapter';
import { getDatosUsers } from '../../api/Login';
import { getTokenApi } from '../../api/Token';
import { ToastContainer, toast, Flip } from 'react-toastify';
import {map} from 'lodash';
import {
    Navigate,useNavigate,useParams 
  } from "react-router-dom";

import { getVentaByFolioApi } from '../../api/Ventas';
import { Button } from 'react-bootstrap';
import './Folio.css'
import QrReader from 'react-qr-reader';
import LoadingSpinner from '../views/LoadingSpinner';
//import PDFViewer from 'pdf-viewer-reactjs';
function LeerQR() {
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState(null);
  const [data, setData] = useState(null);
  const [mensaje,setMensaje] = useState(null);
  //const [delay,setDelay] = (100);
  let navigate = useNavigate();
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      setIsLoading(true);
      const user = await getDatosUsers();
      //console.log(user);
      if(user === null || user.rol == 5){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
      }
      setIsLoading(false);
    }
    init();
  }, [])
  var descontarProducto = async(e)=>{
    //console.log(auth);
    //console.log(e);
    setIsLoading(true);
    const TOKEN = await getTokenApi();
    const formdata = {
        "numFolio":e,
        'id_sucursal':(auth.usuario_sucursal != null ? auth.usuario_sucursal:""),
        "token" : TOKEN,
      }
    //console.log(formdata);
    const resp = await getVentaByFolioApi(formdata);
    if(resp.status == "succes"){
        setData(resp.ventaFolio);
        setMensaje(resp.mensaje);
        setIsLoading(false);
    }else{
      toast.error(resp.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark"
        });
      setIsLoading(false);
    }
  }
  var qrNuevo = async() =>{
    setIsLoading(true);
    const user = await getDatosUsers();
    setData(null);
    setMensaje(null);
    setIsLoading(false);
  }
  return(
    <>
    <div className="contenido">
      <div className="sub_contenidoCenter">
        {
          isLoading ? (
              <LoadingSpinner
              showSpinner={isLoading}
              />
          ):
          (
            <>
            </>
          )
        }
        {
          data !=null ?(
            <><Button onClick={qrNuevo}>Escanear Otro QR</Button><h4>{mensaje}</h4><div className="ticket centrado">
                <h5 className='letra'>MULSO (Multimarcas Soto)</h5>
                <h5 className='letra'>{data[0]['clave_sucursal']} {data[0]['nombre_sucursal']}</h5>
                <h5 className='letra'>{data[0]['direccion_sucursal']}</h5>
                <h5 className='letra'>Telefono :{data[0]['telefono_sucursal']}</h5>
                <h5 className='letra'>Ticket de venta {data[0]['numero_folio']}</h5>
                <h5 className='letra'>{data[0]['fecha_folio']}</h5>
                <h5 className='letra'>Cliente: {data[0]['nombre_cliente_folio']}</h5>
                <h5 className='letra'>Cajero: {data[0]['nombre_usuario']}</h5>
                <table className='etable'>
                  <thead>
                    <tr className="etr centrado">
                      <th className="eth letra cantidad">CANT</th>
                      <th className="eth letra producto">Descripcion</th>
                      <th className="eth letra precio">Total</th>
                    </tr>
                  </thead>
                  <tbody className='ebody'>
                    {data != null && (
                      map(data, (datos, i) => (
                        <tr className="etr" key={i}>
                          <td className="etd cantidad">{datos['cantidad_venta']}</td>
                          <td className="etd producto">{datos['clave_producto']} {datos['descripcion_producto']}</td>
                          <td className="etd precio">${datos['total_venta']}</td>
                        </tr>

                      ))
                    )}
                    <tr className="etr">
                      <td className="etd cantidad"></td>
                      <td className="etd producto">
                        <strong>Articulos Comprados</strong>
                      </td>
                      <td className="etd precio">
                        {data[0]['articulos_comprados']}
                      </td>
                    </tr>
                    <tr className="etr">
                      <td className="etd cantidad"></td>
                      <td className="etd">
                        <strong>TOTAL</strong>
                      </td>
                      <td className="etd precio">
                        ${data[0]['total_vendio_folio']}
                      </td>
                    </tr>
                    <tr className="etr">
                      <td className="etd cantidad"></td>
                      <td className="etd producto">
                        <strong>Pago con</strong>
                      </td>
                      <td className="etd precio">
                        ${data[0]['pago_con_ventas']}
                      </td>
                    </tr>
                    <tr className="etr">
                      <td className="etd cantidad"></td>
                      <td className="etd producto">
                        <strong>Su cambio</strong>
                      </td>
                      <td className="etd precio">
                        ${data[0]['cambio_ventas']}
                      </td>
                    </tr>
                  </tbody>


                </table>
              </div></>
          ):(
            <div className="qrReader">
            {
            <QrReader
            delay={2000}
            onError={(error)=>{if (!!error) {
              console.info(error);
            }}}
            onScan={(result)=> {if (!!result) {
              descontarProducto(result);
            }}}
            style={{ width: '100%' }}
          />
            }
            </div> 
          )
        } 
       
          <ToastContainer
            transition={Flip}
            position="bottom-center"
            limit={1}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
      </div>
      
    </div>
   
   
  </>
  )
}

export default LeerQR;
