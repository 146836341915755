import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
   
} from "react-router-dom";
import Bitacora from '../componentes/home/Bitacora';
import Home from '../componentes/home/Home';
import HistorialProductos from '../componentes/Productos/HistorialProductos';
import Productos from '../componentes/Productos/Productos';
import ProductosInventario from '../componentes/Productos/ProductosInventario';
import CorteDia from '../componentes/sucursales/CorteDia';
import ExistenciaGerente from '../componentes/sucursales/ExistenciaGerente';
import HistorialPrecios from '../componentes/sucursales/HistorialPrecios';
import Sucursales from '../componentes/sucursales/Sucursales';
import SucursalesInventario from '../componentes/sucursales/SucursalesInventario';
import SucursalGerente from '../componentes/sucursales/SucursalGerente';
import Usuarios from '../componentes/usuarios/Usuarios';
import UsuariosSucursal from '../componentes/usuarios/UsuariosSucursal';
import Folio from '../componentes/ventas/Folio';
import FoliosEntregados from '../componentes/ventas/FoliosEntregados';
import LeerQR from '../componentes/ventas/LeerQR';
import RegistroVentas from '../componentes/ventas/RegistroVentas';

import Ventas from '../componentes/ventas/Ventas';
import Layout from '../componentes/views/Layout';
import NotFound from '../componentes/views/NotFound';
import Login from '../sesion/Login';
function Rutas() {
    return (
        <>
        {
            <BrowserRouter>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route path='/login' element={<Login/>}/>
                        <Route path="*" element={<NotFound/>} />
                        <Route path="/users" element={<Usuarios/>} />
                        <Route path="/usersSucursal" element={<UsuariosSucursal/>} />
                        <Route path='/sucursales' element={<Sucursales/>}/>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/productos' element={<Productos/>}/>
                        <Route path="/productos/:productoId" element={<ProductosInventario />}/>
                        <Route path="/sucursal/:sucursalId" element={<SucursalesInventario />}/>
                        <Route path="/sucursal" element={<SucursalesInventario/>}/>
                        <Route path="/ventas" element={<Ventas />}/>
                        <Route path="/leerQR" element={<LeerQR />}/>
                        <Route path="/folio/:folioId" element={<Folio />}/>
                        <Route path="/corte/:sucursalId" element={<CorteDia/>}/>
                        <Route path="/sucursalGerente" element={<SucursalGerente/>}/>
                        <Route path="/existenciaGerente" element={<ExistenciaGerente/>}/>
                        <Route path='/registroventas' element={<RegistroVentas/>}/>
                        <Route path='/ventasSucursal/:sucursalId' element={<RegistroVentas/>}/>
                        <Route path='/historialPrecios/:sucursalId' element={<HistorialPrecios/>}/>
                        <Route path='/historialMovimientos' element={<HistorialPrecios/>}/>
                        <Route path='/bitacora' element={<Bitacora/>}/>
                        <Route path='/historialProductos/:sucursalId' element={<HistorialProductos/>}/>
                        <Route path='/ticketsEntregados' element={<FoliosEntregados/>}/>
                        <Route path='/ticketsUsuarios/:idUser' element={<FoliosEntregados/>}/>
                        {/*
                        <Route path='/ticketsUsuarios' element={<FoliosEntregados/>}/>
                        */}
                    </Route>  
                </Routes>
            </BrowserRouter>
            
        }
        </>
    )
}
export default Rutas; 
