import React,{ useState,useEffect} from 'react';
//import AuthContext from '../../context/AuthContext';
import { getDatosUsers } from '../../api/Login';
import {
  Navigate,useNavigate
} from "react-router-dom";

function Bitacora() {
    const [auth,setAuth] = useState(null);
    
    let navigate = useNavigate();
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
     // console.log(user);
      if(user === null){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
      }
    }
    init();
  }, [])
  return(
    <>
      <div>
        bitacora
      </div>
    </>
  )
}

export default Bitacora;
