import React, {useCallback,useState,useEffect} from 'react';
import { Button ,Modal, Container, Row, Col, Form, InputGroup} from "react-bootstrap";
import {map} from 'lodash';
import Paginacion from '../paginacion/Paginacion';

//import AuthContext from '../../context/AuthContext';
import { getDatosUsers } from '../../api/Login';
import {
  Navigate,useNavigate,useParams
} from "react-router-dom";
import { getHistorialPreciosApi } from '../../api/ProductoSucursal';
import { getTokenApi } from '../../api/Token';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Pagination from 'react-responsive-pagination';
// Bootstrap 4.x styles included somewhere in the project
import 'bootstrap/dist/css/bootstrap.css';
import ReactExport from "react-export-excel";
import LoadingSpinner from '../views/LoadingSpinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function HistorialProductos() {
    const [isLoading, setIsLoading] = useState(false);
    const [auth,setAuth] = useState(null);
    /*cosas para usar la pagiancion*/
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [totalPaginas,setTotalPaginas] = useState(0); 
    const [preciosHistorial,setPreciosHistorial] = useState(null);
    const [fechaini,setFechaini] = useState("");
    const [fechafin,setFechafin] = useState("");
    const [dataReal,setDataReal] = useState(null);

    const [sucursalId,setSucursalId] = useState(0);
    let params = useParams();
    let navigate = useNavigate();

    const getHistorialPrecios = useCallback( async(page_number,fechaini1,fechafin1,idSucursal) => {
        //console.log(buscar);
         const TOKEN = await getTokenApi();
         const formdata = {
             "token" : TOKEN,
             "pagina" : page_number,
             "idSucursal":idSucursal,
             "fechaini":fechaini1,
             "fechafin":fechafin1,
             "band":1,
          }
          setCurrPage(page_number);
          var pro = await getHistorialPreciosApi(formdata);
          //console.log(usuario.totalPaginas);
          setTotalPaginas(pro.totalPaginas); 
          setPreciosHistorial(pro.historialPrecios);  
          setDataReal(pro.total_historialPrecios);
          setIsLoading(false);
      },[]);
   /*Verificar si no ahy un usuario logueado*/
   useEffect(() => {
    const init = async() =>{
      const user = await getDatosUsers();
      //console.log("token",user);
      if(user === null){
        navigate("/login", { replace: true });
      }else{
        setAuth(user);
        setIsLoading(true);
        if (params.sucursalId != null) {
          /**hacer el historial por sucursal del usuario */
          setSucursalId(params.sucursalId);
          getHistorialPrecios(1,"","",params.sucursalId);
        }else{
          setSucursalId(user.usuario_sucursal);
          getHistorialPrecios(1,"","",user.usuario_sucursal);          
        }
        
        
      }
    }
    init();
  }, [])
  var busquedaFuncion = () =>{
    setIsLoading(true);
    //console.log("fechas",fechafin,fechaini);
    getHistorialPrecios(1,fechaini,fechafin,sucursalId)
  }
  function handlePageChange(page) {
    setIsLoading(true);
    setCurrPage(page);
    //console.log("controler normal",page);
    getHistorialPrecios(page,fechaini,fechafin,sucursalId);
    // ... do something with `page`
  }
  return(
    <>
     <div className="contenido">
        <div className="sub_contenido">
            <div className="filtros_pag">
                <Form.Group>
                  <InputGroup>
                    <Form.Control 
                      type="date" 
                      name="horaEntrada"
                      defaultValue={fechaini} 
                      placeholder="Entrada"
                      onChange={event => setFechaini(event.target.value)}
                    />&nbsp;  
                    <Form.Control 
                      type="date" 
                      name="horaSalida" 
                      defaultValue={fechafin} 
                      placeholder="Salida"
                      onChange={event => setFechafin(event.target.value)}
                    />&nbsp;
                    <Button title="Buscar" onClick={busquedaFuncion} className="botonIcono botonNuevo showmessages">Buscar</Button>&nbsp;
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <ExcelFile element={<Button>Descargar Movimientos</Button>}>
                    <ExcelSheet data={dataReal} name="Movimientos Producto" filename="MovimientosProducto">
                      <ExcelColumn label="Clave Sucursal" value="clave_sucursal"/>
                      <ExcelColumn label="Nombre Sucursal" value="nombre_sucursal"/>
                      <ExcelColumn label="Clave Producto" value="clave_producto"/>
                      <ExcelColumn label="Descripcion Producto" value="descripcion_producto"/>
                      <ExcelColumn label="Usuario Modifico" value="user_usuario"/>
                      <ExcelColumn label="Acción Que Realizo" value="accion_realizada_historial_precios"/>
                      <ExcelColumn label="Cantidad Anterior" value="cantidad_anterior_historial_precios"/>
                      <ExcelColumn label="Cantidad Nueva" value="cantidad_nueva_historial_precios"/>
                      <ExcelColumn label="Fecha Cambio" value="fecha_cambio_historial_precios"/>
                    </ExcelSheet>
                  </ExcelFile>
                </Form.Group>
            </div>
            {
              isLoading ? (
                <LoadingSpinner
                showSpinner={isLoading}
                />
              ):(
                <>
                </>
              )
            }
            <Table>
                <Thead>
                    <Tr>
                        <Th className="borderTabla">Sucursal</Th>
                        <Th className="borderTabla">Producto</Th>
                        <Th className="borderTabla">Usuario</Th>
                        <Th className="borderTabla">Acción</Th>
                        <Th className="borderTabla">Descripción</Th>
                        <Th className="borderTabla">Cantidad Anterior</Th>
                        <Th className="borderTabla">Cantidad Nueva</Th>
                        <Th className="borderTabla">Fecha Cambio</Th>     
                    </Tr>
                </Thead>
                <Tbody>
                 {
                    preciosHistorial != null &&(
                        map(preciosHistorial,(phistorial,index) =>(
                          <Tr key={index} className={(index+1) %2 != 0?('fondoTabla'):('') }>
                              <Td className="borderTabla">{phistorial.clave_sucursal} {phistorial.nombre_sucursal}</Td>
                              <Td className="borderTabla">{phistorial.clave_producto} {phistorial.descripcion_producto}</Td>
                              <Td className="borderTabla">{phistorial.user_usuario}</Td>
                              <Td className="borderTabla">{phistorial.accion_historial_precios}</Td>
                              <Td className="borderTabla">{phistorial.accion_realizada_historial_precios}</Td>
                              <Td className="borderTabla">{phistorial.cantidad_anterior_historial_precios}</Td>
                              <Td className="borderTabla">{phistorial.cantidad_nueva_historial_precios}</Td>
                              <Td className="borderTabla">{phistorial.fecha_cambio_historial_precios}</Td>
                          </Tr>
                        ))
                    )
                 }
                </Tbody>
            </Table>
            &nbsp;						
            <div className="alignDerecha">
                {
                    preciosHistorial != null ?
                    ( 
                    <Pagination
                        total={totalPaginas}
                        current={currPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                ):
                (
                    <p>No hay registros</p>
                )
                }
            </div>
        </div>
    </div>
    </>
  )
}

export default HistorialProductos;
